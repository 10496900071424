import { PrivateButton } from './button';
import { mapProps, compose, setDisplayName, defaultProps } from 'react-recompose';
import { display, font } from 'enums';
import { removeProps } from 'components/core/hoc';

const PrimaryButton = compose(
  setDisplayName('PrimaryButton'),
  removeProps(['display']),
  defaultProps({
    font: {
      weight:   font.weights.MEDIUM,
      family:   font.families.CUSTOM,
      emphasis: font.emphasis.CAPS,
    },
  }),
  mapProps(({ isSolid, ...rest }) => ({
    _display: isSolid ? display.SOLID : display.OUTLINE,
    ...rest,
  }))
)(PrivateButton);

export default PrimaryButton;

/*
 text:         PropTypes.string,
 icon:         PropTypes.oneOfType([
   PropTypes.element, //Icon only
     PropTypes.string,
     PropTypes.shape({
       content:  PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
       position: PropTypes.oneOf(positions.LR)
     })
   ]),
 color:        PropTypes.oneOf([colors.ACTION, colors.ACCENT]),
 size:         PropTypes.oneOf(sizes.ALL),

 value:   PropTypes.any,
 onClick: PropTypes.func.isRequired,

 float:      PropTypes.oneOf(positions.LR),
 isActive:   PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isDisabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isLoading:  PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isCompact:  PropTypes.bool,
 isFluid:    PropTypes.bool,
 */
