import { PrivateCheckbox } from '../checkbox';
import { withProps, mapProps, compose, setDisplayName } from 'react-recompose';

const Toggle = compose(
  setDisplayName('Toggle'),
  withProps({ _display: 'toggle' }),
  mapProps(({ isOn, ...rest }) => ({
    isChecked: isOn,
    ...rest,
  }))
)(PrivateCheckbox);

export default Toggle;

/*
 label:       PropTypes.string, // Stardust does not provide support for more custom labels. Can only be applied to the right
 description: PropTypes.string,
 value:       PropTypes.string.isRequired,
 onChange:    PropTypes.func.isRequired,
 isOn:        PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isDisabled:  PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isCompact:   PropTypes.bool
 */
