import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { positions, sizes } from 'enums';
import { toUIProps } from 'helpers';

import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'react-recompose';

import { Image as UIImage } from 'semantic-ui-react';

const ADDED_PROPS = [
  'float',
  'isAvatar',
  'isBordered',
  'isCentered',
  'isHidden',
  'isInline',
  'isDisabled',
  'spacing',
  'handleClick',
  'handleLoad',
  'onLoad',
  'value',
  'id',
];

const MODIFIED_PROPS = [
  'fluid', // moved to the 'size' prop
  'height', // moved to the 'size' prop
  'width', // moved to the 'size' prop
];

const UNSUPPORTED_PROPS = [
  'as',
  'avatar',
  'children',
  'className',
  'bordered',
  'centered',
  'dimmer',
  'disabled',
  'floated',
  'hidden',
  'href',
  'inline',
  'label',
  'spaced',
  'ui',
  'wrapped',
];

const propMap = {
  float:        'floated',
  isAvatar:     'avatar',
  isBordered:   'bordered',
  isCentered:   'centered',
  isRounded:    'rounded',
  isCircular:   'circular',
  isHidden:     'hidden',
  isInline:     'inline',
  spacing:      'spaced',
};

const Image = createComponent({
  displayName: 'Image',
  propTypes:   {
    alt:          PropTypes.string,
    float:        PropTypes.oneOf(positions.LR),
    isAvatar:     PropTypes.bool, // disallow 'rounded', 'circular', 'size', 'height', 'width', 'inline', 'centered', 'spacing'
    isBordered:   PropTypes.bool,
    isRounded:    PropTypes.bool,
    isCircular:   PropTypes.bool,
    isCentered:   PropTypes.bool,
    isHidden:     PropTypes.oneOfType([PropTypes.bool, PropTypes.func]), // disallow 'inline', 'spacing', 'verticalAlign'
    isInline:     PropTypes.bool, // disallow 'isCentered'
    isDisabled:   PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    size:         PropTypes.oneOfType([
      PropTypes.oneOf([...sizes.ALL, 'fluid']),
      PropTypes.shape({
        width:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ]),
    spacing:       PropTypes.oneOf(positions.LRLR), //disallow 'float' and 'isCentered'
    src:           PropTypes.string.isRequired,
    verticalAlign: PropTypes.oneOf(positions.verticalAlign.ALL),
    onClick:       PropTypes.func,
    onLoad:        PropTypes.func,
    value:         PropTypes.any,
    id:            PropTypes.any,
  },
  defaultProps: {
    alt:           '',
    float:         null,
    isAvatar:      false,
    isBordered:    false,
    isCentered:    false,
    isRounded:     false,
    isCircular:    false,
    isHidden:      false,
    isInline:      true,
    isDisabled:    false,
    size:          undefined, // undefined for override detection in ImageGroup
    spacing:       null,
    verticalAlign: null,
    onClick:       undefined,
    onLoad:        undefined,
    value:         null,
    id:            null,
  },
  handlers: {
    handleClick: p => e => {
      if (p.onClick) p.onClick(e, { value: p.value, id: p.id });
    },
    handleLoad: p => e => {
      if (p.onLoad) p.onLoad(e, { value: p.value, id: p.id });
    },
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });
    const isDisabled = typeof props.isDisabled === 'function' ? props.isDisabled() : props.isDisabled;

    // Stardust PropTypes
    UIProps.ui = true;

    if (!!UIProps.spaced) {
      logger.warn(
        'Image: "float" cannot be defined with "spacing", please choose one or the other.',
        !!UIProps.floated
      );
      delete UIProps.floated;
    }

    if (UIProps.hidden) {
      delete UIProps.avatar;
      delete UIProps.inline;
      delete UIProps.spaced;
      delete UIProps.verticalAlign;
    }

    if (UIProps.inline) {
      logger.warn(
        'Image: "isCentered" cannot be defined with "isInline", please choose one or the other.',
        !!UIProps.centered
      );
      delete UIProps.centered; // always inline-block
    }

    if (!!UIProps.spaced) {
      logger.warn(
        'Image: "isCentered" cannot be defined with "spacing", please choose one or the other.',
        !!UIProps.centered
      );
      delete UIProps.centered; // always inline-block
    }

    logger.warn(
      'Image: "isRounded" and "isCircular" are mutually exclusive, please choose one or the other.',
      !!UIProps.rounded && !!UIProps.circular
    );

    if (UIProps.avatar) {
      logger.warn(
        'Image: "isRounded" cannot be defined with "isAvatar", please choose one or the other.',
        !!UIProps.rounded
      );
      logger.warn(
        'Image: "isCircular" cannot be defined with "isAvatar", please choose one or the other.',
        !!UIProps.circular
      );
      logger.warn(
        'Image: "size" cannot be defined with "isAvatar", please choose one or the other.',
        !_.isEmpty(UIProps.size)
      );
      logger.warn(
        'Image: "isInline" cannot be defined with "isAvatar", please choose one or the other.',
        !!UIProps.inline
      );
      logger.warn(
        'Image: "isCentered" cannot be defined with "isAvatar", please choose one or the other.',
        !!UIProps.centered
      );
      logger.warn(
        'Image: "spacing" cannot be defined with "isAvatar", please choose one or the other.',
        !!UIProps.spaced
      );
      delete UIProps.rounded; // always 'circular'
      delete UIProps.circular; // always 'circular'
      delete UIProps.size; // avatars are square of set dimensions
      delete UIProps.inline; // always inline-block
      delete UIProps.centered;
      delete UIProps.spaced; // always spaced right
    }

    if (!_.isEmpty(UIProps.size) && UIProps.size.hasOwnProperty('height')) {
      UIProps.height = UIProps.size.height;
    }
    if (!_.isEmpty(UIProps.size) && UIProps.size.hasOwnProperty('width')) {
      UIProps.width = UIProps.size.width;
    }
    if (
      !_.isEmpty(UIProps.size) &&
      (UIProps.size.hasOwnProperty('height') || UIProps.size.hasOwnProperty('width'))
    ) {
      delete UIProps.size;
    }
    if (UIProps.size === 'fluid') {
      UIProps.fluid = true;
      delete UIProps.size;
    }

    if (UIProps.spaced === positions.LEFT_RIGHT) {
      UIProps.spaced = true;
    }

    if (!isDisabled && props.onClick) {
      UIProps.onClick = props.handleClick;
    } else {
      delete UIProps.onClick;
    }

    UIProps.className = cx({
      clickable: !isDisabled && props.onClick,
      disabled:  isDisabled
    });

    if (props.onLoad) {
      UIProps.onLoad = props.handleLoad;
    }

    return <UIImage {...UIProps} />;
  },
});

export default compose(removeProps(['isAvatar', 'isHidden']), whitelistStyles(['objectFit', 'objectPosition']))(Image);
