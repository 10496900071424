import React from 'react';
import Image from 'components/core/image';
import { createComponent } from 'wayin-react';
import assetRefService from 'helpers/asset';

const InstagramVideo = createComponent({
  displayName: 'InstagramVideo',

  lifecycles: {
    // load instagram scripts
    componentDidMount() {
      // if the script is not downloaded, we download it and it will automatically process to generate an iframe
      if (!document.getElementById('react-instagram-embed-script')) {
        const s = document.createElement('script');
        s.async = s.defer = true;
        s.src = '//www.instagram.com/embed.js';
        s.id = 'react-instagram-embed-script';
        document.body.appendChild(s);
      }
      // if instagram object is already initiated (on the next mount), we have to process it manually
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    },
  },

  render({ src, id, thumbnailAssetRef }) {
    const _src = id ? `//www.instagram.com/p/${id}` : src;
    const asset = assetRefService.deserialize(thumbnailAssetRef);
    return (
      <div className="ck instagram-video">
        <blockquote className="instagram-video__media instagram-media" data-instgrm-permalink={_src} data-instgrm-version="14">
              <If condition={thumbnailAssetRef}>
                <div className="instagram-video__thumbnail">
                  <Image src={assetRefService.cleanUrl(asset.location)} />
                </div>
              </If>
          </blockquote>
      </div>
    );
  },
});

export default InstagramVideo;
