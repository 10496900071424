import React from 'react';
import classnames from 'classnames';
import { Popup as UIPopup } from 'semantic-ui-react';
import { compose } from 'react-recompose';

import _noop from 'lodash/noop';
import _map from 'lodash/map';

import { createComponent, PropTypes } from 'wayin-react';
import { removeProps, whitelistStyles } from 'components/core/hoc';

import { positions, sizes } from 'enums';
import { toUIProps } from 'helpers';

// Semantic has inconsistent descriptions of positions, mapping corner positions to there left|right opposite for clarity
const POSITION_MAP = {
  [positions.TOP]: 'top center',
  [positions.BOTTOM]: 'bottom center',
  [positions.LEFT]: 'left center',
  [positions.RIGHT]: 'right center',
  [positions.TOP_LEFT]: positions.TOP_RIGHT,
  [positions.TOP_RIGHT]: positions.TOP_LEFT,
  [positions.BOTTOM_LEFT]: positions.BOTTOM_RIGHT,
  [positions.BOTTOM_RIGHT]: positions.BOTTOM_LEFT,
};


const UNSUPPORTED_PROPS = [
  'as',
  'basic',
  'className',
  'content',
  'context',
  'disabled',
  'eventsEnabled',
  'flowing',
  'header',
  'hoverable',
  'on',
  'onMount',
  'onUnmount',
  'popper',
  'popperDependencies',
  'popperModifiers',
  'positionFixed',
  'size',
  'wide',
];

const ADDED_PROPS = [
  'closeOnClick',
  'doClose',
  'doOpen',
  'handleClose',
  'handleOpen',
  'isBasic',
  'isFlowing',
  'isHoverable',
  'isInverted',
  'isOpen',
  'showOn',
  'width',
];
const MODIFIED_PROPS = [];

const propMap = {
  showOn: 'on',
  width: 'wide',
  isFlowing: 'flowing',
  isHoverable: 'hoverable',
  isOpen: 'open',
  isBasic: 'basic',
};

const SimplePopup = createComponent({
  displayName: 'SimplePopup',
  propTypes: {
    children: PropTypes.node,
    closeOnClick: PropTypes.bool,
    hideOnScroll: PropTypes.bool,
    isBasic: PropTypes.bool,
    isFlowing: PropTypes.bool,
    isHoverable: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    position: PropTypes.oneOf(positions.ALL),
    showOn: PropTypes.oneOf(['click', 'hover']),
    trigger: PropTypes.any.isRequired,
    width: PropTypes.oneOf(sizes.S_M_L),
    mouseEnterDelay: PropTypes.number,
    mouseLeaveDelay: PropTypes.number,
    offset: PropTypes.array,
  },
  defaultProps: {
    children: undefined,
    closeOnClick: true,
    hideOnScroll: false,
    isBasic: false,
    isFlowing: false,
    isHoverable: true,
    onClose: _noop,
    onOpen: _noop,
    position: positions.BOTTOM,
    showOn: 'hover',
    trigger: null,
    width: sizes.S,
    mouseEnterDelay: 0,
    mouseLeaveDelay: 0,
    offset: [0, 0],
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  handlers: {
    handleOpen: (p) => (e) => {
      p.doOpen();
      if (!!p.onOpen && typeof p.opOpen === 'function') p.onOpen(e);
    },
    handleClose: (p) => (e) => {
      p.doClose();
      if (!!p.onClose && typeof p.opClose === 'function') p.onClose(e);
    },
  },
  state: {
    isOpen: {
      value: false,
      updater(setIsOpen) {
        return {
          doOpen: () => setIsOpen(true),
          doClose: () => setIsOpen(false),
        };
      },
    },
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });

    // add classNames
    UIProps.className = classnames('ck-simple-popup', { inverted: props.isInverted });
    const _triggerCls = classnames('ck-simple-popup-trigger', { open: props.isOpen, inverted: props.isInverted });

    // fix Semantic's wonky position mapping
    UIProps.position = POSITION_MAP[props.position] || props.position;

    // work out the width interface
    if (props.width === sizes.S) UIProps.wide = false;
    if (props.width === sizes.M) UIProps.wide = true;
    if (props.width === sizes.L) UIProps.wide = 'very';

    // make sure we don't pass anything for `wide` when we have `isFLowing`
    if (props.isFlowing) UIProps.wide = undefined;

    // correct for listeners and handlers
    if (props.onOpen) UIProps.onOpen = props.handleOpen;
    if (props.onClose) UIProps.onClose = props.handleClose;
    
    UIProps.trigger = <span className={_triggerCls}>{props.trigger}</span>;
    
    const items = extractChildren(props);

    // Note: for debugging, uncomment this line to leave the popup open for a long time
    // UIProps.mouseLeaveDelay = 15000000000;

    return <UIPopup {...UIProps}>{items}</UIPopup>;

  },
});

function extractChildren(p) {
  return _map(React.Children.toArray(p.children), (item, idx) => {
    const itemProps = { key: `pmi-${idx}` };
    itemProps.onClick = (target, _p) => {
      if (!!item.props.onClick) item.props.onClick(target, _p);
      if (p.closeOnClick) {
        p.doClose();
      }
    };
    return React.cloneElement(item, itemProps);
  });
}

export const PrivateSimplePopup = SimplePopup;
export default whitelistStyles()(SimplePopup);
