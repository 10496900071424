import _values from 'lodash/values';

//The number of months we would like to display for the date range picker.
//We have scenarios when we want to display one month for smaller screens, but would also like to have the option to display more months when ever possible.
const monthsCount = {
  ONE: 'one',
  TWO:  'two',
};

monthsCount.ALL = _values(monthsCount);

export default monthsCount;