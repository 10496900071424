import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps } from 'helpers';
import classnames from 'classnames';
import mapHandlers from 'components/core/hoc/map-handlers';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'react-recompose';

import { Menu as UIMenu } from 'semantic-ui-react';

const UNSUPPORTED_PROPS = [
  'as',
  'className',
  'position', // unsupported until we have a use case for this
];

const ADDED_PROPS = ['isDivided', 'activeValue', 'onItemClick'];

const MenuSubmenu = createComponent({
  displayName: 'Submenu',
  propTypes:   {
    children:    PropTypes.node,
    isDivided:   PropTypes.bool,
    activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onItemClick: PropTypes.func,
  },
  defaultProps: {
    children:    null,
    isDivided:   false,
    activeValue: null,
    onItemClick: null,
  },
  render(props) {
    const UIProps = toUIProps({ props, UNSUPPORTED_PROPS, ADDED_PROPS });
    UIProps.className = classnames({ divided: props.isDivided });
    delete UIProps.children;
    const children = React.Children.map(props.children, (child, idx) => {
      return React.cloneElement(child, {
        activeValue: props.activeValue,
        onItemClick: props.onItemClick,
      });
    });

    return (
      <UIMenu.Menu {...UIProps}>
        {children}
      </UIMenu.Menu>
    );
  },
});

export const PrivateSubmenu = mapHandlers({ itemClick: [] })(MenuSubmenu);
export default compose(removeProps(['onItemClick', 'activeValue']), whitelistStyles())(MenuSubmenu);
