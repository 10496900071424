import React from 'react';
import { createComponent, PropTypes, Utils } from 'wayin-react';
import { toUIProps } from 'helpers';
import { removeProps, whitelistStyles, mapHandlers } from 'components/core/hoc';
import { compose } from 'react-recompose';
import classnames from 'classnames';

import { font, sizes, textOverflows, colors } from 'enums';

import { Card as UICard } from 'semantic-ui-react';
import Image from 'components/core/image';

const UNSUPPORTED_PROPS = [
  'as',
  'className',
  'color',
  'centered',
  'fluid',
  'raised',
  //  may add later
  'extra',
];

const ADDED_PROPS = [
  'id',
  'font',
  'isCentered',
  'isFluid',
  'isInverted',
  'headerStyles',
  'descriptionStyles',
  'children',
];

const MODIFIED_PROPS = ['meta'];

const propMap = {
  isCentered: 'centered',
  isFluid:    'fluid',
  isRaised:   'raised',
};

export const OnHover = createComponent({
  displayName: 'Card.OnHover',
  propTypes:   {
    children: PropTypes.node.isRequired,
    isDimmed: PropTypes.bool.isRequired,
    image:    PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    color:    PropTypes.oneOf([colors.WHITE]) //other colors to be added later
  },
  defaultProps: {
    color: undefined
  },
  render(props) {
    const wrapperClass = classnames({
      'image':  true,
      'dimmed': props.isDimmed,
    });

    const contentClass = classnames({
      'hover-content':                     true,
      [`hover-background-${props.color}`]: !!props.color,
      'dimmed':                            props.isDimmed,
    });

    return (
      <div className="card-hoverable-child">
        <div className={wrapperClass}>
          <Choose>
            <When condition={React.isValidElement(props.image)}>
              {props.image}
            </When>
            <Otherwise>
              <Image src={props.image} />
            </Otherwise>
          </Choose>
        </div>

        <div className={contentClass}>
          <div className="hoverable-content">{props.children}</div>
        </div>
      </div>
    );
  }
});

const Card = createComponent({
  displayName: 'Card',
  propTypes:   {
    header:       PropTypes.string,
    description:  PropTypes.string,
    href:         PropTypes.string,
    image:        PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    meta:         PropTypes.any,
    isCentered:   PropTypes.bool,
    isFluid:      PropTypes.bool,
    onClick:      PropTypes.func,
    headerStyles: PropTypes.shape({
      size: PropTypes.oneOf(sizes.S_M_L),
      font: PropTypes.shape({
        weight:   PropTypes.oneOf(font.weights.ALL),
        family:   PropTypes.oneOf(font.families.ALL),
        emphasis: PropTypes.oneOf(font.emphasis.ALL),
      }),
      textOverflow: PropTypes.string,
    }),
    descriptionStyles: PropTypes.shape({
      textOverflow: PropTypes.string,
    }),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },
  defaultProps: {
    header:            undefined,
    description:       undefined,
    href:              undefined,
    image:             undefined,
    meta:              undefined,
    isCentered:        undefined,
    isFluid:           undefined,
    onClick:           undefined,
    headerStyles:      undefined,
    descriptionStyles: undefined,
    value:             undefined,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },

  state: {
    isDimmed: {
      value:   false,
      updater: setIsDimmed => ({
        dim:   () => setIsDimmed(true),
        undim: () => setIsDimmed(false),
      }),
    },
  },

  render(p) {
    // merge the props with defaultProps, making sure to take account of headerStyles
    const props = _.defaults(p, defaultProps);
    const children = React.Children.toArray(props.children);
    const overlayChild = children.find(child => Utils.isType(OnHover, child));
    const unsupportedChildren = children.find(child => !Utils.isType(OnHover, child));
    const { dim, undim } = props;
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });
    delete UIProps.children;
    delete UIProps.image;
    delete UIProps.header;
    delete UIProps.description;
    delete UIProps.isDimmed;
    delete UIProps.dim;
    delete UIProps.undim;
    const fontStyles = {
      weight: (props.headerStyles.font && props.headerStyles.font.weight) || font.weights.REGULAR,
      family: (props.headerStyles.font && props.headerStyles.font.family) || font.families.CUSTOM,
    };

    UIProps.className = classnames('ck-card', {
      inverted: props.isInverted,
    });

    const headerClassName = classnames(
      props.headerStyles.size,
      `font-${fontStyles.family}-${fontStyles.weight}`,
      {
        caps: isEmphasisCaps(props),
        ...mapTextOverflow(props.headerStyles),
      }
    );


    //loggers
    logger.warn('Card: incorrect child supplied. Please only use Card.OnHover', !!unsupportedChildren);
    logger.warn('Card: Please supply image prop if you plan to use Card.OnHover', !props.image && !!overlayChild);

    const descriptionClassName = classnames({
      ...mapTextOverflow(props.descriptionStyles),
    });

    return (
      <UICard {...UIProps} onMouseEnter={dim} onMouseLeave={undim}>

        <If condition={!!props.image}>
          <Choose>
            <When condition={!overlayChild}>
              <div className="image">
                <Choose>
                  <When condition={React.isValidElement(props.image)}>
                    {props.image}
                  </When>
                  <Otherwise>
                    <Image src={props.image} />
                  </Otherwise>
                </Choose>
              </div>
            </When>

            <Otherwise>
              {React.cloneElement(overlayChild, {
                isDimmed: props.isDimmed,
                image:    props.image,
              })}
            </Otherwise>
          </Choose>
        </If>

        <If condition={!!props.header || !!props.description || !!props.meta}>
          <UICard.Content>
            <If condition={!!props.header}>
              <UICard.Header className={headerClassName} content={props.header} />
            </If>
            <If condition={!!props.description}>
              <UICard.Description className={descriptionClassName} content={props.description} />
            </If>
            <If condition={!!props.meta}>
              <UICard.Meta content={props.meta} />
            </If>
          </UICard.Content>
        </If>
      </UICard>
    );
  },
});

const defaultProps = {
  description:  undefined,
  header:       undefined,
  href:         undefined,
  image:        undefined,
  meta:         undefined,
  onClick:      undefined,
  isCentered:   false,
  isFluid:      false,
  headerStyles: {
    size: sizes.LARGE,
    font: {
      weight:   font.weights.REGULAR,
      family:   font.families.CUSTOM,
      emphasis: font.emphasis.NORMAL,
    },
    textOverflow: textOverflows.WRAP,
  },
  descriptionStyles: {
    textOverflow: textOverflows.WRAP,
  },
  value: undefined,
};

function mapTextOverflow(p) {
  const { textOverflow } = p;
  return {
    [`text-overflow-${textOverflow}`]: _.isString(textOverflow),
  };
}

function isEmphasisCaps(props) {
  // legacy `textEmphasis` prop - keep backwards compatible
  logger.deprecation(
    `Prop "textEmphasis" has been deprecated, and can conflict with
    "font: emphasis", please remove "textEmphasis" from Card.`,
    !!props.headerStyles.textEmphasis
  );

  return (
    props.headerStyles.textEmphasis === font.emphasis.CAPS ||
    (!props.headerStyles.textEmphasis &&
      props.headerStyles.font &&
      props.headerStyles.font.emphasis === font.emphasis.CAPS)
  );
}

export const PrivateCard = mapHandlers({ click: [] })(Card);
export default compose(removeProps(['isRaised']), whitelistStyles())(PrivateCard);
