import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { colors, widths, textAlignments, positions } from 'enums';
import { toUIProps } from 'helpers';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'react-recompose';
import classnames from 'classnames';
import IsInvertedProvider from 'components/abstractions/is-inverted-provider';

import { GridColumn as UIGridColumn } from 'semantic-ui-react';

const ADDED_PROPS = [
  'backgroundColor',
  'columnStyles',
  'columnWidth',
  'float',
  '_parentColumnStyles',
  'isContentCentered',
  'isInverted',
];
const MODIFIED_PROPS = [];
const UNSUPPORTED_PROPS = [
  'as',
  'color',
  'className',
  'computer',
  'floated',
  'largeScreen',
  'mobile',
  'tablet',
  'widescreen',
  'width',
  'only',
];
const propMap = {
  float: 'floated',
};

const LayoutColumn = createComponent({
  displayName: 'LayoutColumn',
  propTypes:   {
    children:        PropTypes.node,
    backgroundColor: PropTypes.oneOf([...colors.CORE, 'transparent']),
    columnWidth:     PropTypes.oneOfType([
      PropTypes.oneOf(widths.ALL),
      PropTypes.shape({
        mobile:      PropTypes.oneOf(widths.ALL),
        tablet:      PropTypes.oneOf(widths.ALL),
        computer:    PropTypes.oneOf(widths.ALL),
        largeScreen: PropTypes.oneOf(widths.ALL),
        widescreen:  PropTypes.oneOf(widths.ALL),
      }),
    ]),
    float:             PropTypes.oneOf(positions.LR),
    isInverted:        PropTypes.bool,
    textAlign:         PropTypes.oneOf(textAlignments.ALL),
    verticalAlign:     PropTypes.oneOf(positions.alignColumns.ALL),
    isContentCentered: PropTypes.bool,

    _parentColumnStyles: PropTypes.shape({
      backgroundColor:   PropTypes.oneOf(colors.CORE),
      float:             PropTypes.oneOf(positions.LR),
      isInverted:        PropTypes.bool,
      textAlign:         PropTypes.oneOf(textAlignments.ALL),
      verticalAlign:     PropTypes.oneOf(positions.alignColumns.ALL),
      isContentCentered: PropTypes.bool,
    }),
  },
  defaultProps: {
    children:            undefined,
    columnWidth:         null,
    backgroundColor:     undefined,
    float:               undefined,
    isInverted:          undefined, //Gets merged with context value which provides the default value
    textAlign:           undefined,
    verticalAlign:       undefined,
    isContentCentered:   undefined,
    _parentColumnStyles: {},
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(p) {
    const columnStyles = getColumnStyles(p._parentColumnStyles, p);
    const props = Object.assign({}, p, columnStyles);

    if (columnStyles.verticalAlign === positions.alignColumns.FLEX) {
      props.verticalAlign = null;
      props.stretched = true;
    } else {
      props.verticalAlign = columnStyles.verticalAlign;
      props.stretched = false;
    }

    logger.warn(
      `HEADS UP, you passed a margin style to <Layout.Column>,
      which will cause columns to wrap to new rows unexpectingly,
      did you mean to pass padding instead? Or did you hit a use
      case to warrant deleting this warning?`,
      !!props.style && !!props.style.margin
    );

    let UIProps = toUIProps({ props, propMap, ADDED_PROPS, MODIFIED_PROPS, UNSUPPORTED_PROPS });
    delete UIProps.children;

    UIProps = Object.assign({}, UIProps, mapColumns(p));

    UIProps.className = classnames({
      [props.backgroundColor]: props.backgroundColor !== 'transparent',
      inverted:                props.isInverted,
      'centered-content':      props.isContentCentered,
    });

    /*
      Need the provider here to ensure children have a matching inverted state
    */
    return (
      <IsInvertedProvider isInverted={props.isInverted}>
        <UIGridColumn {...UIProps}>{props.children}</UIGridColumn>
      </IsInvertedProvider>
    );
  },
});

// Don't want to set these as defaultProps because then we can't determine if they were set by the user or not
const defaultColumnStyles = {
  backgroundColor:   'transparent', //transparent grid by default
  float:             null,
  textAlign:         null,
  verticalAlign:     positions.alignColumns.TOP,
  isContentCentered: false,
};

function getColumnStyles(parent, overrides) {
  const columnStyles = Object.assign({}, defaultColumnStyles, parent);
  return _.mapValues(columnStyles, (value, key) => {
    return getDefaultOrOverrideVal(value, overrides[key]);
  });
}

function getDefaultOrOverrideVal(defaultVal, overrideVal) {
  return overrideVal === undefined ? defaultVal : overrideVal;
}

function mapColumns({ columnWidth }) {
  if (!!columnWidth) {
    return {
      ...(typeof columnWidth === 'string' && { width: columnWidth }),
      ...(columnWidth.mobile && { mobile: columnWidth.mobile }),
      ...(columnWidth.tablet && { tablet: columnWidth.tablet }),
      ...(columnWidth.computer && { computer: columnWidth.computer }),
      ...(columnWidth.largeScreen && { largeScreen: columnWidth.largeScreen }),
      ...((columnWidth.widescreen || columnWidth.wideScreen) && {
        widescreen: columnWidth.widescreen || columnWidth.wideScreen,
      }), // low key will update wideScreen to widescreen
    };
  } else return columnWidth;
}

export const PrivateLayoutColumn = whitelistStyles([
  'display',
  'alignItems',
  'alignContent',
  'flexDirection',
  'flexWrap',
  'justifyContent',
])(LayoutColumn);
export default removeProps(['_parentColumnStyles'])(PrivateLayoutColumn);
