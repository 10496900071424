import { PrivateButton } from './button';
import { withProps, compose, setDisplayName, defaultProps } from 'react-recompose';
import { removeProps } from 'components/core/hoc';
import { display, font } from 'enums';

const SecondaryButton = compose(
  setDisplayName('SecondaryButton'),
  removeProps(['isSolid', '_display']),
  withProps({
    _display: display.PLAIN,
  }),
  defaultProps({
    hasHoverBorder: false,
    font: {
      emphasis: font.emphasis.CAPS,
    },
  })
)(PrivateButton);

export default SecondaryButton;

/*
 text:         PropTypes.string,
 color:        PropTypes.oneOf(colors.ALL),
 size:         PropTypes.oneOf(sizes.ALL),

 value:   PropTypes.any,
 onClick: PropTypes.func.isRequired,

 float:      PropTypes.oneOf(positions.LR),
 isActive:   PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isDisabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isLoading:  PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isCompact:  PropTypes.bool,
 isFluid:    PropTypes.bool,
 */
