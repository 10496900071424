import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import classnames from 'classnames';
import { toUIProps } from 'helpers';
import { positions, sizes, colors } from 'enums';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'react-recompose';
import { extractElement } from 'helpers';

import { PrivateMenu } from 'components/core/menu/menu';
import { PrivateHeader } from 'components/core/header/header';
import Icon from 'components/core/icon';
import PopupMenuItem from './popup-menu-item';
import { Popup as UIPopup } from 'semantic-ui-react';

// <PopupMenu
//   items={ [{ array of popup menu item properties }] }
//   trigger={ <Icon/> or text }
//   position={ positions.ALL }
//   onItemClick={ myHandler() }
// >
//   <PopupMenu.Item /> ...
// </PopupMenu>

// Semantic has inconsistent descriptions of positions, mapping corner positions to their left|right opposite for clarity
const POSITION_MAP = {
  [positions.TOP]: 'top center',
  [positions.BOTTOM]: 'bottom center',
  [positions.LEFT]: 'left center',
  [positions.RIGHT]: 'right center',
  [positions.TOP_LEFT]: positions.TOP_RIGHT,
  [positions.TOP_RIGHT]: positions.TOP_LEFT,
  [positions.BOTTOM_LEFT]: positions.BOTTOM_RIGHT,
  [positions.BOTTOM_RIGHT]: positions.BOTTOM_LEFT,
};

const PopupMenu = createComponent({
  displayName: 'PopupMenu',
  propTypes: {
    // items:       PropTypes.arrayOf(PropTypes.object), // removing items shorthand; leaving in place in case it is needed later.
    children: PropTypes.node,
    trigger: PropTypes.any.isRequired,
    position: PropTypes.oneOf(positions.ALL),
    offset: PropTypes.number, // private
    onItemClick: PropTypes.func,
    closeOnClick: PropTypes.bool,
    hideOnScroll: PropTypes.bool,
  },
  defaultProps: {
    // items:       undefined,
    children: undefined,
    trigger: null,
    position: positions.BOTTOM,
    offset: null,
    onItemClick: null,
    closeOnClick: true,
    hideOnScroll: false,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  state: {
    isOpen: {
      value: false,
      updater(setIsOpen, props) {
        return {
          doOpen: () => setIsOpen(true),
          doClose: () => setIsOpen(false),
        };
      },
    },
  },
  handlers: {
    handleItemClick: (p) => (e, data) => {
      p.onItemClick(e, data);
      if (p.closeOnClick) {
        p.doClose();
      }
    },
  },
  render(p) {
    const items = extractChildren(p);
    const position = POSITION_MAP[p.position] || p.position;
    const trigger = (
      <span className={classnames('ck-popup-menu-trigger', { open: p.isOpen }, { inverted: p.isInverted })}>
        {p.trigger}
      </span>
    );

    // logger.warn(
    //   'PopupMenu: Children cannot be defined with "items", please choose one or the other.',
    //   !!p.items && !!p.children);

    // Note: for debugging, add this to UIPopup to be able to see the popup in devtools
    // mouseLeaveDelay={15000000000}
    return (
      <UIPopup
        className="ck-popup-menu-parent"
        trigger={trigger}
        position={position}
        open={p.isOpen}
        onClose={p.doClose}
        onOpen={p.doOpen}
        offset={p.offset}
        style={p.style}
        hoverable
        basic
        hideOnScroll={p.hideOnScroll}
      >
        <PrivateMenu
          menuType="popup-menu"
          isVertical
          borders="noDividers"
          size={sizes.X5}
          onItemClick={p.handleItemClick}
          isFluid={false}
        >
          {items}
        </PrivateMenu>
      </UIPopup>
    );
  },
});

function extractChildren(p) {
  return _.map(React.Children.toArray(p.children), (item, idx) => {
    const itemProps = { key: `pmi-${idx}` };
    // set a default icon size
    if (!!item.props.icon) {
      const icon = extractElement(Icon, item.props.icon, 'name').element;
      itemProps.icon = React.cloneElement(icon, { size: icon.props.size || sizes.X5 });
    }

    if (!!item.props.onClick) {
      itemProps.onClick = (target, _p) => {
        item.props.onClick(target, _p);
        if (p.closeOnClick) {
          p.doClose();
        }
      };
    }
    return React.cloneElement(item, itemProps);
  });
}

export const PrivatePopupMenu = PopupMenu;
export default compose(removeProps(['offset']), whitelistStyles())(PopupMenu);
