import 'react-dates/initialize';
import { range } from 'lodash';
import moment from 'moment';
import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import classnames from 'classnames';
import { DateRangePicker as ReactDateRangePicker } from 'react-dates';
import { positions, sizes, monthsCount } from 'enums';
import { whitelistStyles } from 'components/core/hoc';
import Button from 'components/core/button';
import Label from 'components/core/label';
import ErrorIcon from '../../abstractions/error-icon';
import evalPredicate from '../../../util/eval-predicate';
import { extractElement } from 'helpers';

const defaultRanges = [
  {
    label: 'Today',
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  },
  {
    label: 'Yesterday',
    startDate: moment()
      .startOf('day')
      .subtract(1, 'day'),
    endDate: moment()
      .endOf('day')
      .subtract(1, 'day'),
  },
  {
    label: 'Last 7 days',
    startDate: moment()
      .startOf('day')
      .subtract(6, 'day'),
    endDate: moment().endOf('day'),
  },
  {
    label: 'Last 14 days',
    startDate: moment()
      .startOf('day')
      .subtract(13, 'day'),
    endDate: moment().endOf('day'),
  },
  {
    label: 'Last 30 days',
    startDate: moment()
      .startOf('day')
      .subtract(29, 'day'),
    endDate: moment().endOf('day'),
  },
  {
    label: 'All Time',
    startDate: null,
    endDate: null,
  },
];

const positionToABNBPosition = {
  [positions.LEFT]: 'before',
  [positions.RIGHT]: 'after',
  [positions.TOP]: 'top',
  [positions.BOTTOM]: 'bottom',
};

//'-' sign between the start - end date of the input
const CustomArrowIcon = () => <span>-</span>;

const CustomRanges = ({ ranges, rangesPosition, onChange, onClose, isInverted, showCloseButton , isCalendarCompact}) => {
  return (
    <React.Fragment>
      <div className={
        classnames('date-ranges-custom', {
          'date-ranges-custom--horizontal-align': rangesPosition === positions.TOP || rangesPosition === positions.BOTTOM,
        })
      }> 
        <For each="range" index="i" of={ranges}>
          <div key={`range-${i}`}>
            <Button.Secondary
              size={isCalendarCompact ? sizes.X2 : sizes.X3 }
              text={range.label}
              onClick={() => {
                onChange({
                  startDate: range.startDate,
                  endDate: range.endDate,
                });
              }}
              isInverted={isInverted}
              isCompact
            />
          </div>
        </For>
      </div>
      <If condition={showCloseButton}>
        <div className="close-btn" key="range-ok">
          <Button.Primary text="Close" onClick={onClose} isInverted={isInverted} isCompact />
        </div>
      </If>
    </React.Fragment>
  );
};

const DateRangePicker = createComponent({
  displayName: 'DateRangePicker',
  propTypes: {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isDisabled: PropTypes.bool,
    showClearIcon: PropTypes.bool,
    value: PropTypes.shape({
      startDate: PropTypes.object,
      endDate: PropTypes.object,
    }).isRequired, //an object of {startDate, endDate} props
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onChange: PropTypes.func.isRequired,
    ranges: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        startDate: PropTypes.object,
        endDate: PropTypes.object,
      })
    ),
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    displayFormat: PropTypes.string,
    anchorDirection: PropTypes.oneOf(positions.LR),
    rangesPosition: PropTypes.oneOf(positions.LRTB),
    iconPosition: PropTypes.oneOf(positions.LR),
    errorMessage: PropTypes.string,
    errorMessagePosition: PropTypes.oneOf(positions.ALL),
    hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    numberOfMonths: PropTypes.oneOf(monthsCount.ALL),
    isCalendarCompact: PropTypes.bool,
    showCloseButton: PropTypes.bool,
  },
  defaultProps: {
    id: undefined,
    isDisabled: false,
    showClearIcon: false,
    label: null,
    ranges: defaultRanges,
    displayFormat: 'MMM D YYYY',
    anchorDirection: positions.RIGHT,
    rangesPosition: positions.RIGHT,
    iconPosition: positions.RIGHT,
    errorMessage: null,
    errorMessagePosition: positions.TOP_LEFT,
    hasError: false,
    minDate: null,
    maxDate: null,
    numberOfMonths: monthsCount.TWO,
    isCalendarCompact: false,
    showCloseButton: true,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },

  state: {
    focusedInput: {
      value: null,
      updater: 'setFocusedInput',
    },

    //airbnb 'react dates' component isn't automatically moving the months when a date range is set programatically (so a custom date might not be visible in the day pickers)
    //because of this, we need to re-render the whole component, whenever we want to programatically set a date...
    rerenderKey: {
      value: 1,
      updater: 'setRerenderKey',
    },
  },

  handlers: {
    handleChange: p => ({ startDate, endDate, rerenderKey }) => {
      p.onChange({}, { id: p.id, value: { startDate, endDate } });
      setTimeout(() => {
        p.setRerenderKey(!!rerenderKey ? rerenderKey : p.rerenderKey + 1); //this will triger a re-render of the airbnb date range picker
      }, 100);
    },
    onClosePicker: p => () => {
      p.setFocusedInput(null);
    },
  },

  render(p) {
    const hasError = evalPredicate(p.hasError) || !!p.errorMessage;

    const dateRangeLabel = extractElement(Label, p.label, {
      text: p.label,
      isDisabled: p.isDisabled,
      isInverted: p.isInverted,
    });

    logger.warn(
      'DateRangePicker: propTypes "isDisabled" and "hasError" both set to true, this is invalid, please choose one or the other.',
      p.isDisabled && hasError
    );

    const wrapperClassName = classnames('ck-date-range-picker', {
      inverted: p.isInverted,
      disabled: p.isDisabled,
      expanded: p.focusedInput,
      error: !!hasError,
    });

    const renderCalendarInfo = () => {
      return (
        <CustomRanges
          ranges={p.ranges}
          onChange={p.handleChange}
          onClose={p.onClosePicker}
          isInverted={p.isInverted}
          showCloseButton={p.showCloseButton}
          rangesPosition={p.rangesPosition}
          isCalendarCompact={p.isCalendarCompact}
        />
      );
    };

    const isDayBlocked = day => (!!p.minDate && day.isBefore(p.minDate)) || (!!p.maxDate && day.isAfter(p.maxDate));

    return (
      <div className={wrapperClassName} style={p.style}>
        <If condition={dateRangeLabel}>
          <div className="date-time-picker__label">{dateRangeLabel.element}</div>
        </If>
        <div className="error-wrapper">
          <ReactDateRangePicker
            key={`rerender-${p.rerenderKey}`}
            disabled={p.isDisabled}
            showClearDates={p.showClearIcon}
            startDate={p.value.startDate}
            startDateId={`${p.id}_start_date`} //accessability related
            endDate={p.value.endDate}
            endDateId={`${p.id}_end_date`} //accessability related
            onDatesChange={({ startDate, endDate }) => p.handleChange({ startDate, endDate, rerenderKey: 1 })}
            focusedInput={p.focusedInput}
            onFocusChange={focusedInput => p.setFocusedInput(focusedInput)}
            orientation="horizontal"
            anchorDirection={p.anchorDirection}
            renderCalendarInfo={renderCalendarInfo}
            calendarInfoPosition={positionToABNBPosition[p.rangesPosition]}
            showDefaultInputIcon={true}
            inputIconPosition={positionToABNBPosition[p.iconPosition]}
            isOutsideRange={() => false}
            isDayBlocked={isDayBlocked}
            displayFormat={p.displayFormat}
            minDate={p.minDate}
            maxDate={p.maxDate}
            customArrowIcon={<CustomArrowIcon />} /* adds a thinner '-' instead of the wider arrow icon */
            minimumNights={0} /* this allows the range picker to choose the same date as start/end */
            weekDayFormat="ddd"
            numberOfMonths={p.numberOfMonths === monthsCount.ONE ? 1 : 2}
            daySize={p.isCalendarCompact ? 30 : 40}
          />
          <div className="error-content">
            <ErrorIcon errorMessagePosition={p.errorMessagePosition} errorMessage={p.errorMessage} />
          </div>
        </div>
      </div>
    );
  },
});

export default whitelistStyles()(DateRangePicker);
