const coreColors = {
  ACCENT:  'accent',
  ACTION:  'action',
  BASE:    'base',
  DARK:    'dark',
  DARKER:  'darker',
  ERROR:   'error',
  SUPER:   'super',
  WHITE:   'white',
  WARNING: 'warning',
};

const extendedColors = {
  BASIC:     'basic',
  ADVANCED:  'advanced',
  GREY:      'grey',
  STEELBLUE: 'steelblue',
  HUNTER:    'hunter',
};

const colors = _.assign({}, coreColors, extendedColors);

colors.CORE = _.values(coreColors);
colors.EXTENDED = _.values(extendedColors);

colors.ALL = _.union(colors.CORE, colors.EXTENDED)

export default colors;
