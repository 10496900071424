import { PrivateAssetUploader } from './asset-uploader';
import { compose, setDisplayName, withProps } from 'react-recompose';
import { removeProps } from 'components/core/hoc';

const MediaAssetUploader = compose(
  setDisplayName('MediaAssetUploader'),
  withProps({
    showUploadButton:   true,
    showSelectButton:   true,
  })

)(PrivateAssetUploader);

export default MediaAssetUploader;