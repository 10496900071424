import React from 'react';
import PropTypes from 'prop-types';

import { Utils } from 'wayin-react';
import classnames from 'classnames';
import { sizes } from 'enums';
import { Form } from 'semantic-ui-react';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'react-recompose';

import Checkbox, { PrivateCheckbox, isComponentDisabled } from '../checkbox-like/checkbox';
import Toggle from '../checkbox-like/toggle';
import Label from 'components/core/label';

const CHECKBOX_TYPES = ['Checkbox', 'Toggle'];

// TODO: update to createComponent when custom prop validation is supported



class CheckboxGroup extends React.Component {
  static displayName = 'CheckboxGroup';

  static propTypes = {
    children: checkboxComponents,
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
    onChange: PropTypes.func.isRequired, // will override any child's onChange handler
    selectedValues: PropTypes.arrayOf(PropTypes.string), // will override any child's isChecked or isOn prop - must match value prop of checkboxes
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.oneOf(CHECKBOX_TYPES),
        description: PropTypes.string,
        isDisabled: PropTypes.bool,
      })
    ),
    label: PropTypes.string,
    spacing: PropTypes.oneOf(sizes.S_M_L),
    gutterSize: PropTypes.oneOf(sizes.S_M_L),
  };

  static defaultProps = {
    children: null,
    isDisabled: false,
    layout: 'vertical',
    selectedValues: [],
    options: [],
    label: null,
    spacing: sizes.MEDIUM,
    gutterSize: sizes.MEDIUM,
  };

  onChange = (event, data) => {
    const props = this.props;
    const selectedValues = !!data.checked
      ? [...props.selectedValues, data.value]
      : _.reject(props.selectedValues, value => value === data.value);
    const newData = Object.assign({}, data, { selectedValues });
    props.onChange(event, newData);
  };
  render() {
    // TODO use additional semantic Form props here as needed to meet styling and behavior reqs
    const props = this.props;
    let groupUIProps = {},
      controls;

    if (props.layout === 'horizontal') {
      groupUIProps.inline = true;
    } else if (props.layout === 'vertical') {
      groupUIProps.grouped = true;
    }

    logger.warn(
      'Children cannot be defined with "options", please choose one or the other, in CheckboxGroup',
      !_.isEmpty(props.options) && !_.isEmpty(React.Children.toArray(props.children))
    );

    if (!_.isEmpty(props.options)) {
      controls = extractControlsFromOptions(props, this.onChange);
    } else {
      controls = extractControlsFromChildren(props, this.onChange);
    }

    const fields = _.map(controls, (control, idx) => {
      return <Form.Field key={idx}>{control}</Form.Field>;
    });

    const checkboxGroupClassnames = classnames('ck-checkbox-group', `${props.layout}-spacing`, props.spacing);

    return (
      <div className={checkboxGroupClassnames} style={props.style}>
        <Form>
          <If condition={props.label}>
            <Label text={props.label} />
          </If>
          <Form.Group {...groupUIProps}>{fields}</Form.Group>
        </Form>
      </div>
    );
  }
}

function extractControlsFromChildren(props, onChange) {
  return React.Children.map(props.children, input => {
    const isDisabled = isComponentDisabled(props.isDisabled) || isComponentDisabled(input.props.isDisabled);
    if (Utils.isType([Checkbox, PrivateCheckbox], input)) {
      return React.cloneElement(input, {
        onChange: onChange,
        isChecked: _.includes(props.selectedValues, input.props.value),
        isDisabled: isDisabled,
        gutterSize: input.props.gutterSize !== undefined ? input.props.gutterSize : props.gutterSize,
      });
    } else {
      return React.cloneElement(input, {
        onChange: onChange,
        isOn: _.includes(props.selectedValues, input.props.value),
        isDisabled: isDisabled,
        gutterSize: props.gutterSize,
      });
    }
  });
}

function extractControlsFromOptions(props, onChange) {
  let Component, isChecked;
  const componentIsDisabled = isComponentDisabled(props.isDisabled);

  return _.map(props.options, option => {
    switch (option.type) {
      case 'Checkbox':
        Component = Checkbox;
        isChecked = 'isChecked';
        break;
      case 'Toggle':
        Component = Toggle;
        isChecked = 'isOn';
        break;
      default:
        Component = Checkbox;
        isChecked = 'isChecked';
    }

    return (
      <Component
        isChecked={_.includes(props.selectedValues, option.value)}
        value={option.value}
        onChange={onChange}
        label={option.label}
        isDisabled={componentIsDisabled || isComponentDisabled(option.isDisabled)}
        description={option.description}
        gutterSize={props.gutterSize}
      />
    );
  });
}

function checkboxComponents(props) {
  let warning;
  React.Children.forEach(props.children, input => {
    warning = logger.warn(
      'Invalid component supplied to `CheckboxGroup` children, expected Checkbox, or Toggle',
      !Utils.isType([Checkbox, PrivateCheckbox, Toggle], input)
    );
  });
  return warning;
}

export const PrivateCheckboxGroup = CheckboxGroup;
export default compose(removeProps(['layout']), whitelistStyles())(CheckboxGroup);
