import { createReducer } from 'redux-act';

import Immutable from 'seamless-immutable';

import Actions from './actions';

/*
  Example state tree for Form.

  { //  ck.form is defined in coreapp when injecting this reducer
    ck: {
      form: {

        //  form id
        profile: {

          //  form level errors
          form: {
            errors: ['password required', 'password doesn\'t match']
            validators: {
              formValidations: (formValues, validationContexts) => {

              },
              passwordMatch: (formValues, validationContexts) => {
                if (formValues.password !== formValues.confirmPassword) {
                  return 'Password doesn\'t match';
                }
                return true
              }
            }
          },

          //  password field id
          password: {
            value: '',
            validators: {
              isRequired: (value, validationContext) => {
                if (value == undefined || value === '') {
                  return 'Is Required';
                }
                if (validationContext.type !== 'password-group') {
                  return 'Invalid group';
                }
                return true;
              },
              isValidLength: (value, validationContext) => {
                if (_.get(value, 'length') < x) {
                  return 'Must be X characters'
                }
                return true
              },
              isValid: (value, validationContext) => {
                const specialRegex;
                if (!specialRegex.test(value)) {
                  return 'Must contain a special character'
                }
                return true;
              }
            },
            errors: ['Is Required', 'Must be X characters', 'Must contain a special character'],
            isDirty: false
          },

          //  confirm password field id
          confirmPassword: {
            value: 'password2',
            errors: ['Password doesn\'t match'],
            isDirty: false
          }
        }
      }
    }
  }
}
*/

const initialState = Immutable({});

const updateFieldErrors = (state, formId, fieldErrors) => {
  let updatedState = state;
  _.forEach(fieldErrors, (errors = [], fieldId) => {
    let fieldLevelErrors = _.get(state, [formId, fieldId, 'errors'], []);

    //  some errors may be duplicates
    fieldLevelErrors = _.union(fieldLevelErrors, errors);

    //  update the errors for the field
    updatedState = updatedState.setIn([formId, fieldId, 'errors'], fieldLevelErrors);
  });
  return updatedState;
};

const formReducer = createReducer(on => {
  on(Actions.registerForm, (state, { formId, validators }) => {
    const formExists = _.get(state, [formId]) !== undefined;
    if (!formId) {
      logger.error('Invalid formId');
      return state;
    }

    //  returning original state if form exists
    if (formExists) {
      logger.error(`Form already exists - ${formId}`);
      return state;
    }

    return state.set(formId, { form: { validators } });
  });
  on(Actions.deregisterForm, (state, { formId }) => {
    return state.without(formId);
  });
  on(
    Actions.registerField,
    (state, { formId, fieldId, value, validationContext = {}, validators = {}, errorMessages = [] }) => {
      const isDirty = false;
      const formExists = _.get(state, [formId]) !== undefined;

      if (!formExists) {
        logger.error(`Form doesn't exist - ${formId}`);
        return state;
      }

      if (!fieldId) {
        logger.error('Field id invalid');
        return state;
      }

      const fieldExists = _.get(state, [formId, fieldId]) !== undefined;
      if (fieldExists) {
        // commenting out this warning as fields get registered on mount which happens when navigating between tbs
        // logger.warn(`Field with id -> ${fieldId} already exists`);
        return state;
      }

      return state.setIn([formId, fieldId], { value, validationContext, validators, errors: errorMessages, isDirty });
    }
  );
  on(Actions.deregisterField, (state, { formId, fieldId }) => {
    const form = _.get(state, formId, null);
    if (form && form.hasOwnProperty(fieldId)) {
      const update = _.omit(form, fieldId);
      return state.setIn([formId], update);
    } else {
      return state;
    }
  });

  on(Actions.formChanged, (state, { formId, formErrors, fieldErrors }) => {
    //  update the form level errors
    let updatedState = state.setIn([formId, 'form', 'errors'], formErrors);

    //  update the field level errors
    updatedState = updateFieldErrors(updatedState, formId, fieldErrors);
    return updatedState;
  });

  on(Actions.updateFormFieldErrors, (state, { formId, fieldErrors }) => updateFieldErrors(state, formId, fieldErrors));

  on(Actions.fieldChanged, (state, { formId, fieldId, value, validationContext, errorMessages }) => {
    //this action could be called before the field gets registered, so make sure the field is already there
    const fieldExists = _.get(state, [formId, fieldId]) !== undefined;
    if (fieldExists){
      const previousValue = _.get(state, [formId, fieldId, 'value']);
      const isDirty = _.get(state, [formId, fieldId, 'isDirty']) || previousValue !== value;
      const update = {
        errors: errorMessages,
        isDirty,
        value,
        validationContext,
      };
      let field = _.get(state, [formId, fieldId]);
      field = field.merge(update);
      return state.setIn([formId, fieldId], field);
    } else {
      logger.warn(`Field '${fieldId}' is not yet registered on the '${formId}' form while fieldChanged is trying to update it's validation state.`);
      return state;
    }    
  });
}, initialState);

export default formReducer;
