import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps, extractElement } from 'helpers';
import { icons } from 'enums';
import classnames from 'classnames';
import { whitelistStyles } from 'components/core/hoc';

import { Modal as UIModal } from 'semantic-ui-react';
import Icon from 'components/core/icon';
import Button from 'components/core/button';
import IsInvertedProvider from 'components/abstractions/is-inverted-provider';

/*
  Semantic uses <Portal> inside of <Modal> and assigns/divides props provided to <Modal>
  appropriatly. Thus any prop needed of <Portal> can be defined on and passed to <Modal>
*/

/* this is a workaround to a known modal/ scrolling bug in semantic-react-ui
 * https://github.com/Semantic-Org/Semantic-UI-React/issues/1157
 * they are saying, that https://github.com/Semantic-Org/Semantic-UI-React/pull/2407 should fix the above issue, so I'm commenting out this code
 */
// function fixBody(mountNode) {
//   const root = mountNode || document.body;
//   const anotherModal = document.getElementsByClassName('ui page modals').length;
//   const anotherScrollingModal = document.getElementsByClassName('ui modal scrolling').length;
//   if (anotherModal > 0) {
//     if (anotherScrollingModal) root.classList.add('scrolling', 'dimmable', 'dimmed');
//     else root.classList.add('dimmable', 'dimmed');
//   } else {
//     /**
//      * Remove leftover classes if there are no other modals. This is a workaround for issue where body classes
//      * are not removed after at least 2 modals unmount successively. It causes root to be not scrollable.
//      */
//     root.classList.remove('scrolling', 'dimmable', 'dimmed');
//   }
// }

const UNSUPPORTED_PROPS = [
  'as',
  'actions',
  'basic',
  'className',
  'closeIcon',
  'closeOnDimmerClick',
  'closeOnDocumentClick',
  'content',
  'defaultOpen',
  'dimmer',
  'eventPool',
  'header',
  'open',
  'size',
];
const ADDED_PROPS = ['hasCloseIcon', 'isInverted', 'isFullscreen', 'showModal', 'isSolid'];
const MODIFIED_PROPS = [];

const Modal = createComponent({
  displayName: 'Modal',
  propTypes: {
    trigger: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    children: PropTypes.node.isRequired,
    hasCloseIcon: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    isSolid: PropTypes.bool,
    showModal: PropTypes.bool,
    mountNode: PropTypes.any,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
  },
  defaultProps: {
    trigger: null,
    children: null,
    hasCloseIcon: true,
    isFullscreen: false,
    isSolid: false,
    showModal: undefined,
    mountNode: null,
    onClose: null,
    onOpen: null,
    onMount: null,
    onUnmount: null,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  handlers: {
    onUnmount: (p) => (e) => {
      //fixBody(p.mountNode);
      if (p.onUnmount) return p.onUnmount();
    },
  },
  render(props) {
    // HEADS UP - Modal renders outside of its parent, based on the "mountNode" prop
    const UIProps = toUIProps({ props, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });

    UIProps.trigger =
      props.trigger &&
      (React.isValidElement(props.trigger)
        ? props.trigger
        : extractElement(Button.Primary, props.trigger, 'text').element);
    UIProps.closeIcon = props.hasCloseIcon ? <Icon name={icons.CANCEL} /> : null;
    UIProps.dimmer = props.isInverted ? 'inverted' : true;
    UIProps.className = classnames({
      inverted: props.isInverted,
      fullscreen: props.isFullscreen,
      solid: props.isSolid,
      /*
        This is hopefully temporary to address
        https://github.com/Semantic-Org/Semantic-UI-React/issues/1867
      */
      scrolling: props.isFullscreen,
    });
    UIProps.open = props.showModal;

    delete UIProps.children;

    logger.warn(
      `HEADS UP, you passed a margin style to <Modal>,
      which will cause its internal centering calculation to be off,
      did you mean to set padding? Or did you find a use case to warrant deleting this warning?`,
      !!props.style && !!props.style.margin
    );

    return (
      <div className="ck-modal">
        <UIModal {...UIProps}>
          <IsInvertedProvider isInverted={props.isInverted}>{props.children}</IsInvertedProvider>
        </UIModal>
      </div>
    );
  },
});

export default whitelistStyles()(Modal);
