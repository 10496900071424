import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { sizes, positions, colors, font, display, icons } from 'enums';
import { extractElement, toUIProps } from 'helpers';
import classnames from 'classnames';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'react-recompose';

import { Label as UILabel } from 'semantic-ui-react';
import Icon from '../icon';
import Image from '../image';

const UNSUPPORTED_PROPS = [
  'as',
  'active',
  'basic',
  'circular',
  'className',
  'children',
  'content',
  'detail',
  'empty',
  'floating',
  'horizontal',
  'tag',
  'removeIcon',
];

const ADDED_PROPS = [
  'handleClick',
  'handleRemove',
  'hasTag',
  'hasPadding',
  'isBasic',
  'isCircular',
  'isFloating',
  'isInverted',
  'isDisabled',
  'isFluid',
  'isActive',
  'text',
  'font',
  'display',
  'value',
];

const MODIFIED_PROPS = ['icon', 'image', 'color'];

const propMap = {
  hasTag:     'tag',
  hasPadding: 'horizontal',
  isCircular: 'circular',
  isFloating: 'floating',
  isActive:   'active',
};

const Label = createComponent({
  displayName: 'Label',
  propTypes:   {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.oneOfType([
      PropTypes.element, // Icon only
      PropTypes.string,
      PropTypes.shape({
        content:  PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),
    image: PropTypes.oneOfType([
      PropTypes.element, // Image only
      PropTypes.string,
    ]),
    hasPadding: PropTypes.bool,
    hasTag:     PropTypes.bool,
    display:    PropTypes.oneOf(display.ALL),
    isCircular: PropTypes.bool,
    isFloating: PropTypes.bool,
    isFluid:    PropTypes.bool,
    isDisabled: PropTypes.bool,
    isActive:   PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    attached:   PropTypes.oneOf(positions.WITHOUT_SIDES),
    color:      PropTypes.oneOf(_.without(colors.CORE, colors.ACTION)),
    font:       PropTypes.shape({
      family:   PropTypes.oneOf(font.families.ALL),
      emphasis: PropTypes.oneOf(font.emphasis.ALL),
      weight:   PropTypes.oneOf(font.weights.ALL),
    }),
    size:     PropTypes.oneOf(sizes.ALL),
    corner:   PropTypes.oneOf(positions.LR),
    pointing: PropTypes.oneOf(positions.pointing.ALL),
    ribbon:   PropTypes.oneOf(positions.LR),
    onClick:  PropTypes.func,
    onRemove: PropTypes.func,
    value:    PropTypes.any,
  },
  defaultProps: {
    attached: null,
    color:    null,
    font:     {
      family:   font.families.SYSTEM,
      emphasis: font.emphasis.NORMAL,
      weight:   font.weights.REGULAR,
    },
    text:       null,
    corner:     null,
    hasTag:     false,
    hasPadding: false,
    display:    display.PLAIN,
    isCircular: false,
    isFloating: false,
    isFluid:    false,
    isActive:   false,
    isDisabled: false,
    icon:       null,
    image:      null,
    onClick:    null,
    onRemove:   null,
    pointing:   null,
    ribbon:     null,
    size:       sizes.X4,
    value:      null,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  handlers: {
    handleClick: p => e => {
      p.onClick(e, { value: p.value || p.text });
    },
    handleRemove: p => e => {
      p.onRemove(e, { value: p.value });
    },
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, MODIFIED_PROPS, ADDED_PROPS });

    UIProps.onClick = !!props.onClick ? props.handleClick : undefined;

    const icon = extractElement(Icon, props.icon, 'name');
    const image = extractElement(Image, props.image, 'src');

    if (props.ribbon === 'left') {
      UIProps.ribbon = true;
    }

    UIProps.basic = props.display === display.PLAIN;

    const fontStyles = {
      family:   props.font.family || font.families.SYSTEM,
      emphasis: props.font.emphasis || font.emphasis.NORMAL,
      weight:   props.font.weight || font.weights.REGULAR,
    };

    UIProps.className = classnames('ck', props.color, `font-${fontStyles.family}-${fontStyles.weight}`, {
      fluid:           props.isFluid,
      inverted:        props.isInverted,
      disabled:        props.isDisabled,
      image:           !!props.image,
      caps:            fontStyles.emphasis === font.emphasis.CAPS,
      clickable:       props.onClick,
      [props.display]: props.display !== display.PLAIN,
    });

    return (
      <UILabel {...UIProps}>
        {!!image && image.position === positions.LEFT && image.element}
        {!!icon && icon.position === positions.LEFT && icon.element}
        <span className="ck-label-text">{props.text}</span>
        {!!icon && icon.position === positions.RIGHT && icon.element}
        {!!props.onRemove && <Icon name={icons.CANCEL} size={sizes.X2} onClick={props.handleRemove} isCompact />}
      </UILabel>
    );
  },
});

export const PrivateLabel = Label;
// TODO: if we support `onClick` prop in Label, add `value` prop for consistency, and to pass to the callback
export default compose(
  removeProps([
    'image',
    'isCircular',
    'isFloating',
    'hasTag',
    'attached',
    'corner',
    'pointing',
    'ribbon',
    'onRemove',
    'isActive',
  ]),
  whitelistStyles()
)(Label);
