export const regexMap = {
  youtube:   /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i,
  vimeo:     /(?:https?:\/\/(?:www\.|player\.)?)?vimeo\.com?\/(?:video\/)?(\d+)\/?/,
  instagram: /^(?:https?:\/\/(?:www\.)?)?instagram\.com(?:\/(p|reel)\/([a-zA-Z0-9\-]*)\/?)/,
  vine:      /(?:https?:\/\/(?:www\.)?)?vine\.co(?:\/v\/([a-zA-Z0-9]*)\/?)/,
  twitter:   /^(?:https?:)?\/\/twitter\.com\/(?:#!\/)?\w+\/status(?:es)?\/(\d+)/,
  facebook:  /^https?:\/\/www\.facebook\.com\/(?:video\.php\?v=|.*?\/videos\/)(\d+)/,
  html5:     /^(https?:)?\/\/.+\/.+\.(webm|mp4|ogg|ogv).*$/,
  url:       /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?.*$/,
  mux:       /^(?:https:)?(?:\/\/stream\.mux\.com\/)([a-zA-Z0-9]*)(?:\.m3u8)(?:\?.*)?$/,
};

function getServiceId(serviceName, url) {
  if (!regexMap.hasOwnProperty(serviceName)) {
    throw new Error(`${serviceName} not handled`);
  }

  const regex = regexMap[serviceName];
  const matchs = regex.exec(url);

  const isValidUrl = matchs !== null && matchs.length >= 2;
  if (!isValidUrl) {
    throw new Error(`${url} is not a ${serviceName} url`);
  }

  return matchs[1];
}

export function isYoutubeVideo(url) {
  return regexMap.youtube.test(url);
}

export function getYoutubeId(url) {
  return getServiceId('youtube', url);
}

export function isVimeoVideo(url) {
  return regexMap.vimeo.test(url);
}

export function getVimeoId(url) {
  return getServiceId('vimeo', url);
}

export function isInstagramVideo(url) {
  return regexMap.instagram.test(url);
}

export function getInstagramId(url) {
  return getServiceId('instagram', url);
}

export function isVineVideo(url) {
  return regexMap.vine.test(url);
}

export function getVineId(url) {
  return getServiceId('vine', url);
}

export function isTwitterVideo(url) {
  return regexMap.twitter.test(url);
}

export function getTwitterId(url) {
  return getServiceId('twitter', url);
}

export function isFacebookVideo(url) {
  return regexMap.facebook.test(url);
}

export function isMuxVideo(url) {
  return regexMap.mux.test(url);
}

export function getMuxId(url) {
  return getServiceId('mux', url);
}

export function getFacebookId(url) {
  return getServiceId('facebook', url);
}

export function isValidURL(url) {
  return regexMap.url.test(url);
}

export function isHtml5Video(url) {
  return regexMap.html5.test(url);
}

export function getHtml5Id(url) {
  return getServiceId('html5', url);
}
