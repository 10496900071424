import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { wrapDisplayName, compose } from 'react-recompose';
import capitalize from 'helpers/capitalize';
/*
  {
    click: ['name']
  }
 */
const withHandlers = handlers => Component => {
  const handlerNames = _.keys(handlers);

  return createComponent({
    displayName: wrapDisplayName(Component, 'withHandlers'),
    propTypes:   {
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    },
    defaultProps: {
      id: undefined,
    },
    handlers: buildHandler(handlers, Component),
    render(props) {
      return <Component {...updateProps(handlerNames, props)} />;
    },
  });
};

// click => handleClick
function handlify(handlerName) {
  return `handle${capitalize(handlerName)}`;
}

// click => onClick
function onify(handlerName) {
  return `on${capitalize(handlerName)}`;
}

// Maps handleClick to onClick and deletes handleClick
function updateProps(handlerNames, props) {
  const _props = _.clone(props);

  _.forEach(handlerNames, name => {
    if (!_.isNil(_props[onify(name)])) {
      _props[onify(name)] = _props[handlify(name)];
    }
    delete _props[handlify(name)];
  });

  return _props;
}

/*
 { click: ['name'] }

  =>

 {
  handleClick: p => (e, data) => {
    p.onClick(e, { value, id, name }
  }
 }
 */
function buildHandler(handlers) {
  const _handlers = _.mapValues(handlers, (dataKeys, eventName) => {
    dataKeys.push('id', 'value');

    return p => (e, data = {}) => {
      const _data = _.reduce(
        dataKeys,
        (acc, key) => {
          if (_.has(data, key)) {
            acc[key] = data[key];
          } else if (_.has(p, key)) {
            acc[key] = p[key];
          }
          return acc;
        },
        {}
      );

      if (p.isDisabled !== true) {
        p[onify(eventName)](e, _data);
      }
    };
  });
  return _.mapKeys(_handlers, (handlerFuncs, eventName) => handlify(eventName));
}

export default withHandlers;
