import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { positions, sizes, icons, colors } from 'enums';
import { toUIProps } from 'helpers';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'react-recompose';
import cx from 'classnames';
import evalPredicate from '../../../util/eval-predicate';

import Label from '../label';
import ErrorIcon from '../../abstractions/error-icon';

const propMap = {
  handleChange:  'onChange',
  hasFocus:      'focus',
  isDisabled:    'disabled',
  isFluid:       'fluid',
  //isLoading:     'loading',
  isTransparent: 'transparent',
  isInverted:    'inverted',
  isResizable:   'resizable',
};
const ADDED_PROPS = [
  'errorMessage',
  'handleChange',
  'hasError',
  'hasFocus',
  'isDisabled',
  'isFluid',
  //'isLoading',
  'isTransparent',
  'isInverted',
  'isResizable',
  'label',
  //'innerLabel',
  'maxChars',

  'textAreaValue',
  'setTextAreaValue',
  'handleChange',
  'onChange',
  'value',
];
const MODIFIED_PROPS = ['error'];
const UNSUPPORTED_PROPS = [
  'as',
  'children',
  'className',
  'disabled',
  'error',
  'focus',
  'fluid',
  //'iconPosition',
  'inverted',
  'textarea',
  'labelPosition',
  'loading',
  'transparent',
];

const TextArea = createComponent({
  displayName: 'TextArea',
  propTypes:   {
    /*icon:          PropTypes.oneOfType([
      PropTypes.element, //Icon
      PropTypes.string,
      PropTypes.shape({
        content:  PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
        position: PropTypes.oneOf(positions.LR)
      })
    ]),
    innerLabel:    PropTypes.oneOfType([
      PropTypes.element, //Label
      PropTypes.string,
      PropTypes.shape({
        content:  PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
        position: PropTypes.oneOf(positions.LR)
      })
    ]),*/
    label:    PropTypes.string,
    maxChars: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        content:  PropTypes.number,
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),
    onChange:             PropTypes.func,
    onClick:              PropTypes.func,
    hasError:             PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    hasFocus:             PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isDisabled:           PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isFluid:              PropTypes.bool,
    //isLoading:     PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isTransparent:        PropTypes.bool,
    isResizable:          PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    errorMessage:         PropTypes.string,
    errorMessagePosition: PropTypes.oneOf(positions.ALL),
    size:                 PropTypes.oneOf(sizes.ALL),
    placeholder:          PropTypes.string,
    value:                PropTypes.string,
    id:                   PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    innerRef:             PropTypes.func,
  },
  defaultProps: {
    //icon:          null,
    label: null,
    //innerLabel:    null,

    hasError:             false,
    hasFocus:             null,
    isDisabled:           false,
    isFluid:              true,
    //isLoading:     false,
    isTransparent:        false,
    isResizable:          false,
    errorMessage:         null,
    errorMessagePosition: positions.TOP_LEFT,
    size:                 sizes.X5,
    placeholder:          '',
    maxChars:             null,
    onChange:             null,
    onClick:              null,
    value:                '',
    id:                   undefined,
    innerRef:             undefined,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  handlers: {
    handleChange: p => e => {
      const value = e.target.value || '';
      if (p.onChange) p.onChange(e, { value, id: p.id }); // All our handlers have this signature
    },
    handleClick: p => e => {
      const value = e.target.value || '';
      p.onClick && p.onClick(e, { value, id: p.id });
    },
    onFocus: p => e => {
      p.setFocus(true);
    },
    onBlur: p => e => {
      p.setFocus(false);
    },
  },
  state: {
    focus: false,
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, MODIFIED_PROPS, UNSUPPORTED_PROPS, ADDED_PROPS });

    delete UIProps.fluid;
    delete UIProps.error;

    //let icon = extractElement(Icon, props.icon, 'name', positions.RIGHT);
    //let innerLabel = extractElement(Label, props.innerLabel, 'text', positions.LEFT);

    //builds out the object for 'remaining characters'

    if (
      // we need to perform a different check for the maxChars it's an object
      _.isObject(props.maxChars) ?
        props.maxChars.content !== null && props.maxChars.content !== undefined :
        props.maxChars !== null
    ) {
      UIProps.maxLength = _.isObject(props.maxChars) ? props.maxChars.content : props.maxChars || 0;

      const remainingChars = UIProps.maxLength - calcLength(props.value);
      const remainingCharsLabel = <Label text={remainingChars.toString()} />;
      const remainingCharsLabelPosition = _.get(props.maxChars, 'position', positions.RIGHT);
      const remainingCharsClassNames = cx({
        'max-char':                    'max-char',
        'max-char-warning':            _.inRange(remainingChars, 4, 11),
        'max-char-error':              remainingChars <= 3,
        [remainingCharsLabelPosition]: remainingCharsLabelPosition,
      });
      UIProps.remainingCharCount = <div className={remainingCharsClassNames}>{remainingCharsLabel}</div>;
      UIProps.remainingCharCountPosition = remainingCharsLabelPosition;
    }
    // When both are defined and positioned to the same side we want to render the Icon as a prop on Label
    // This allows us to leverage semantic's built in styling
    /*if (!!innerLabel && !!icon && innerLabel.position === icon.position) {
      innerLabel.element = React.cloneElement(innerLabel.element, { icon: props.icon });
      icon = null;
    }*/

    /*UIProps.icon              = !!icon   ? icon.element    : null;
    UIProps.iconPosition      = !!icon && icon.position === positions.LEFT  ? icon.position : null; //Due to Stardust propTypes
    UIProps.label             = !!innerLabel  ? innerLabel.element   : null;
    UIProps.labelPosition     = !!innerLabel  ? innerLabel.position  : null;*/

    /*if (!!UIProps.loading && !UIProps.icon) {
      // Stardust loading state needs presence of any icon
      UIProps.icon = 'loading';
      UIProps.iconPosition = positions.LEFT;
    }*/

    const taClasses = cx(
      'ui',
      UIProps.size,
      UIProps.disabled && 'disabled',
      UIProps.resizable && 'resizable',
      //(evalPredicate(props.hasError) || !!props.errorMessage) && 'error',
      UIProps.focus && 'focus',
      //UIProps.fluid && 'fluid',
      UIProps.inverted && 'inverted',
      (UIProps.labelPosition && UIProps.labelPosition !== true && UIProps.labelPosition + ' labeled') ||
        (UIProps.label && 'labeled'),
      (UIProps.remainingCharCountPosition &&
        UIProps.remainingCharCountPosition !== true &&
        UIProps.remainingCharCountPosition + ' labeled') ||
        (UIProps.remainingCharCount && 'labeled'),
      UIProps.loading && 'loading',
      UIProps.transparent && 'transparent',
      'textarea',
      { focus: props.hasFocus === null && props.focus }
    );
    //(UIProps.iconPosition && UIProps.iconPosition !== true && UIProps.iconPosition + ' icon') || (UIProps.icon && 'icon'),

    const wrapperClassName = cx('ck textarea', {
      fluid: props.isFluid,
      error: evalPredicate(props.hasError) || !!props.errorMessage,
    });

    return (
      <div className={wrapperClassName} style={props.style}>
        <If condition={props.label}>
          <Label text={props.label} />
        </If>
        <div className="error-wrapper">
          <div className={taClasses} onFocus={props.onFocus} onBlur={props.onBlur} onClick={props.handleClick}>
            {UIProps.labelPosition === 'left' && UIProps.label}
            {UIProps.remainingCharCountPosition === 'left' && UIProps.remainingCharCount}
            {
              <textarea
                placeholder={UIProps.placeholder}
                onChange={props.handleChange}
                value={props.value}
                ref={props.innerRef}
              />
            }
            {UIProps.labelPosition !== 'left' && UIProps.label}
            {UIProps.remainingCharCountPosition !== 'left' && UIProps.remainingCharCount}
          </div>
          <ErrorIcon errorMessagePosition={props.errorMessagePosition} errorMessage={props.errorMessage} />
        </div>
      </div>
    );
    //{UIProps.iconPosition === 'left' && UIProps.icon}
    //{UIProps.iconPosition !== 'left' && UIProps.icon}
  },
});

const calcLength = str => {
  const urlRegExp = RegExp(
    /(https?:\/\/)([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)((\/[%\-\w]+(\.\w{2,})?)*(([\w\-\.\?\\\/+@&#;`~=%!]*)(\.\w{2,})?)*\/)?/g
  );
  const twitLinkLength = 23;
  const linkMatch = str.match(urlRegExp) || [];
  const linkCount = linkMatch.length;
  const yourLinkLength = _.reduce(linkMatch, (count, l) => l.length + count, 0);

  return str.length - yourLinkLength + linkCount * twitLinkLength;
};

export const PrivateTextArea = TextArea;
export default compose(removeProps(['transparent', 'size']), whitelistStyles())(TextArea);
