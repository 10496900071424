import { withProps, compose, setDisplayName } from 'react-recompose';
import removeProps from '../hoc/remove-props';

import Dropdown from './dropdown';

const SingleSelect = compose(
  setDisplayName('SingleSelect'),
  removeProps(['allowMultiple', 'isInline', 'icon']),
  withProps({
    allowMultiple: false,
  })
)(Dropdown);

export default SingleSelect;

/*
 options: PropTypes.arrayOf(
   PropTypes.shape({
     text:  PropTypes.string,
     value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
   })
 ).isRequired,
 value:   PropTypes.oneOfType([PropTypes.string, PropTypes.number ]),
 label:   PropTypes.string,
 placeholder: PropTypes.string,

 isLoading:   PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
 isError:     PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
 isDisabled:  PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
 isFluid:     PropTypes.bool,

 onAddItem:        PropTypes.func,
 additionLabel:    PropTypes.string,
 allowMultiple:    PropTypes.bool,

 // True- Enable searching with the default search function string.contains
 // False- Disable searching
 // ()- Enable searching but use provided function
 search:           PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
 noResultsMessage: PropTypes.string,

 onChange:       PropTypes.func.isRequired,
 onSearchChange: PropTypes.func,
 */
