import { withProps, compose, setDisplayName } from 'react-recompose';
import { PrivateMenu } from 'components/core/menu/menu';
import { removeProps } from '../hoc';

/*
 TODO: currently styled to take full height of its container when horizontal.
       Add a prop to make this an option, when we have the need for a set height
*/

// <TabMenu
//   items=[]
//   activeValue={0}
//   float={positions.R}
//   onItemClick={myHandler()}
//   fitted='all'
//   isFluid={ true }
//   isVertical={ true }
// /> // also could use children instead of items

const TabMenu = compose(
  setDisplayName('TabMenu'),
  removeProps([
    'color',
    'size',
    'menuType',
    'borders',
    'iconTextDisplay',
    'isCompact',
    'isPaginated',
    'isTextOnly',
    'isUnderlined',
    'label',
  ]),
  withProps({ isUnderlined: true })
)(PrivateMenu);

export default TabMenu;
