/**
 * defines components for 'paging'
 * sets the new page number
 */
import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import Layout from '../layout';
import Input from '../input';
import Button from '../button';
import Label from '../label';
import { positions, sizes, colors, widths, textAlignments, icons } from 'enums';
import cx from 'classnames';
import evalPredicate from './../../../util/eval-predicate';
import { whitelistStyles } from 'components/core/hoc';

const BUTTON_ACTIONS = {
  SET_FIRST: 'first',
  SET_PREV:  'prev',
  SET_NEXT:  'next',
  SET_LAST:  'last',
};

/**
 * the paging component
 */
const Paging = createComponent({
  displayName: 'Paging',

  propTypes: {
    itemsTotal:      PropTypes.number.isRequired,
    currentPage:     PropTypes.number.isRequired,
    itemsPerPage:    PropTypes.number.isRequired,
    id:              PropTypes.string,
    pagePrefixLabel: PropTypes.string,
    pageSuffixLabel: PropTypes.string,
    displayLabel:    PropTypes.string,
    noDataLabel:     PropTypes.string,
    isDisabled:      PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    tooltip:         PropTypes.shape({
      firstPage:    PropTypes.string, 
      lastPage:     PropTypes.string, 
      nextPage:     PropTypes.string, 
      previousPage: PropTypes.string, 
      refresh:      PropTypes.string
    }),

    onChange:  PropTypes.func.isRequired,
    onRefresh: PropTypes.func,
  },
  defaultProps: {
    id:              '',
    pagePrefixLabel: 'Page',
    pageSuffixLabel: 'of {total}',
    displayLabel:    'Displaying {from} - {to} of {total}',
    noDataLabel:     'No data to display',
    isDisabled:      false,
    tooltip:         { 
      firstPage:    '', 
      lastPage:     '', 
      nextPage:     '', 
      previousPage: '', 
      refresh:      '' 
    },
    onRefresh:       undefined
  },

  handlers: {
    handleTextChange: p => e => {
      const value = e.target.value || '';
      const totalPages = Math.ceil(p.itemsTotal / p.itemsPerPage);
      p.onChange(e, { value: Math.min(value, totalPages), id: p.id }); // All our handlers have this signature
    },
    handleTextOnBlur: p => e => {
      const value = e.target.value || '';
      if (value == '') p.onChange(e, { value: 1, id: p.id }); // All our handlers have this signature
    },
    handlePageChange: p => (e, v) => {
      let { currentPage, itemsTotal, itemsPerPage } = p,
        totalPages = Math.ceil(itemsTotal / itemsPerPage),
        newPage;

      switch (v.value) {
      case BUTTON_ACTIONS.SET_FIRST:
        newPage = 1;
        break;
      case BUTTON_ACTIONS.SET_PREV:
        newPage = currentPage ? currentPage - 1 : 1;
        break;
      case BUTTON_ACTIONS.SET_NEXT:
        newPage = currentPage ? currentPage + 1 : totalPages;
        break;
      case BUTTON_ACTIONS.SET_LAST:
        newPage = totalPages;
        break;
      }
      p.onChange(e, { value: newPage, id: p.id }); // All our handlers have this signature
    },
    handleRefresh: p => e => {
      p.onRefresh(e, { value: p.currentPage, id: p.id });
    },
  },

  contextTypes: {
    isInverted: PropTypes.bool,
  },

  render(props) {
    const {
      currentPage,
      itemsTotal,
      itemsPerPage,
      isInverted,
      pagePrefixLabel,
      pageSuffixLabel,
      displayLabel,
      noDataLabel,
      tooltip
    } = props;
    logger.warn(
      'Paging: tooltip.refresh property has been set without an onRefresh callback. The tooltip will not be visible. Please declare an associate onRefresh callback or remove the tooltip declaration.',
      tooltip.refresh && !props.onRefresh
    );

    let isDisabled = evalPredicate(props.isDisabled),
      classNames = cx('ck paging', isInverted && 'inverted', isDisabled && 'disabled'),
      totalPages = Math.ceil(itemsTotal / itemsPerPage),
      displayPage = currentPage ? Math.min(currentPage, totalPages) : '', //making sure we stay inside the page count
      displayFrom = itemsPerPage * (displayPage ? displayPage - 1 : 0) + 1,
      displayTo = Math.min(displayFrom + itemsPerPage - 1, itemsTotal);

    return (
      <div className={classNames} style={props.style}>
        <Layout
          columns={widths.TWO}
          columnStyles={{ verticalAlign: positions.alignColumns.MIDDLE }}
        >
          <Layout.Column textAlign={textAlignments.LEFT}>

            <Button.Secondary
              icon={icons.ARROW_FIRST}
              isCompact
              color={colors.BASE}
              size={sizes.X4}
              onClick={props.handlePageChange}
              value={BUTTON_ACTIONS.SET_FIRST}
              isDisabled={isDisabled || !displayPage || displayPage == 1}
              tooltip={tooltip.firstPage}
            />

            <Button.Secondary
              icon={icons.ARROW_PREVIOUS}
              isCompact
              color={colors.BASE}
              size={sizes.X4}
              onClick={props.handlePageChange}
              value={BUTTON_ACTIONS.SET_PREV}
              isDisabled={isDisabled || !displayPage || displayPage == 1}
              tooltip={tooltip.previousPage}
            />

            <Label text={pagePrefixLabel} isDisabled={isDisabled} />

            <Input
              isFluid={false}
              type="number"
              value={displayPage}
              onChange={props.handleTextChange}
              onBlur={props.handleTextOnBlur}
              isDisabled={isDisabled}
            />

            <Label text={pageSuffixLabel.replace('{total}', totalPages)} isDisabled={isDisabled} />

            <Button.Secondary
              icon={icons.ARROW_NEXT}
              isCompact
              color={colors.BASE}
              size={sizes.X4}
              onClick={props.handlePageChange}
              value={BUTTON_ACTIONS.SET_NEXT}
              isDisabled={isDisabled || !displayPage || displayPage == totalPages}
              tooltip={tooltip.nextPage}
            />

            <Button.Secondary
              icon={icons.ARROW_LAST}
              isCompact
              color={colors.BASE}
              size={sizes.X4}
              onClick={props.handlePageChange}
              value={BUTTON_ACTIONS.SET_LAST}
              isDisabled={isDisabled || !displayPage || displayPage == totalPages}
              tooltip={tooltip.lastPage}
            />

            <If condition={props.onRefresh}>
              <Button.Secondary
                icon={icons.REFRESH}
                isCompact
                color={colors.BASE}
                size={sizes.X4}
                onClick={props.handleRefresh}
                tooltip={tooltip.refresh}
              />            
            </If>  

          </Layout.Column>
          <Layout.Column textAlign={textAlignments.RIGHT}>
            <Label
              text={
                itemsTotal > 0
                  ? displayLabel
                      .replace('{from}', displayFrom)
                      .replace('{to}', displayTo)
                      .replace('{total}', itemsTotal)
                  : noDataLabel
              }
              isDisabled={isDisabled}
            />
          </Layout.Column>
        </Layout>
      </div>
    );
  },
});

export default whitelistStyles()(Paging);
