import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps } from 'helpers';
import classnames from 'classnames';
import { removeProps, whitelistStyles, mapHandlers } from 'components/core/hoc';
import { compose, withProps, setDisplayName } from 'react-recompose';

import { Checkbox as UICheckbox } from 'semantic-ui-react';

import { sizes } from 'enums';

const ADDED_PROPS = [
  'isCompact',
  'id',
  'isOn',
  'isSelected',
  'isChecked',
  'isDisabled',
  'isInverted',
  'description',
  'gutterSize',
  'checkSize',
  '_display',
];
const MODIFIED_PROPS = ['style'];
const UNSUPPORTED_PROPS = [
  'as',
  'className',
  'checked',
  'defaultChecked',
  'defaultIndeterminate',
  'indeterminate',
  'disabled',
  'onClick', // use onChange
  'readOnly', // input rendered with readOnly attribute regardless of this prop (prop disables toggling - same as isDisabled)
  'radio',
  'slider',
  'toggle',
];

const propMap = {
  isChecked:  'checked',
  isDisabled: 'disabled',
};

/**
 * Function can be used by checkbox group, radio group or the checkbox itself, to tell if the box itself should be disabled
 * @param {bool/func} isDisabled - component isDisabled property
 * @returns bool - true/false
 */
export const isComponentDisabled = (isDisabled) => {
  if (isDisabled !== undefined){
    return typeof isDisabled === 'function' ? isDisabled() : !!isDisabled;
  }
  return false;  
}

const Checkbox = createComponent({
  displayName: 'Checkbox',
  propTypes:   {
    label:       PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // Stardust does not provide support for more custom labels. Can only be applied to the right
    description: PropTypes.string,
    value:       PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    onChange:    PropTypes.func.isRequired,
    gutterSize:  PropTypes.oneOf(sizes.S_M_L),
    checkSize:   PropTypes.oneOf([sizes.X4, sizes.X5, sizes.X6]),

    isChecked:  PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isCompact:  PropTypes.bool,
    _display:   PropTypes.oneOf(['checkbox', 'toggle', 'radio']),
    name:       PropTypes.string,
  },
  defaultProps: {
    label:       null,
    description: null,
    isChecked:   false,
    isDisabled:  false,
    isCompact:   undefined,
    gutterSize:  sizes.M,
    checkSize:   sizes.X4,
    // not false, so that it can be
    // set by CheckboxGroup if not present
    _display:    'checkbox',
    name:        null,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  refs:       'checkboxRef',
  handlers: {
    handleDescriptionClick: p => (e) => {
      p.refs.checkboxRef.handleChange(e); //calling the UICheckbox handler, so it properly updates it's internal state
    },
  },
  render(props) {
    const { setCheckboxRef, handleDescriptionClick, ...cbProps } = props;
    const UIProps = toUIProps({
      props: cbProps,
      propMap,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
      MODIFIED_PROPS,
    });

    if (props._display === 'radio') {
      UIProps.radio = true;
      UIProps.type = 'radio';
    } else if (props._display === 'toggle') {
      UIProps.toggle = true;
      UIProps.type = 'checkbox';
    } else {
      UIProps.type = 'checkbox';
    }

    UIProps.className = classnames({
      inverted: props.isInverted,
      fitted:   props.isCompact,
    });

    const isDisabled = isComponentDisabled(props.isDisabled);
    const checkboxClassnames = classnames('ck-checkbox', props._display, {
      description:                              props.description,
      [`checkbox-gutter--${props.gutterSize}`]: props.gutterSize,
      [`checkbox-check--${props.checkSize}`]:   props.checkSize,
    });

    const descriptionClassnames = classnames('ck-checkbox-description', {
      inverted: props.isInverted,
      disabled: isDisabled,
    });

    return (
      <div className={checkboxClassnames} style={props.style}>
        <UICheckbox {...UIProps} ref={props.setCheckboxRef}/>
        <If condition={!!props.description}>
          <div
            onClick={isDisabled ? _.noop : props.handleDescriptionClick}
            className={descriptionClassnames}
          >
            {props.description}
          </div>
        </If>
      </div>
    );
  },
});

export const PrivateCheckbox = mapHandlers({ change: ['checked'] })(Checkbox);
export default compose(
  setDisplayName('Checkbox'),
  removeProps(['_display']),
  withProps({ _display: 'checkbox' }),
  whitelistStyles()
)(PrivateCheckbox);
