const _sizes = {
  X1: 'mini',
  X2: 'tiny',
  X3: 'small',
  X4: 'medium',
  X5: 'large',
  X6: 'big',
  X7: 'huge',
  X8: 'massive',
};


const sizes = {..._sizes};

sizes.ALL = _.values(_sizes);

sizes.BASE = _sizes;

sizes.SMALL = sizes.S = sizes.X3;
sizes.MEDIUM = sizes.M = sizes.X4;
sizes.LARGE = sizes.L = sizes.X5;
sizes.S_M_L = [sizes.SMALL, sizes.MEDIUM, sizes.LARGE];

export default sizes;
