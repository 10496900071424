/**
 * contains utility functions used by the light uploader component
 */
import { documentTypes, assetServices, uploadAssetTypes } from 'enums';
import { getYoutubeId } from 'helpers';
import { ConfigService } from 'services';
import assetRefService from 'helpers/asset';

/**
 * define characters that are not accepted in a file name. Unaccepted characters will be removed from the files name just before upload.
 */
export const UNACCEPTED_FILE_NAME_CHARACTERS = /[^a-zA-Z0-9\._\- ]/g;

//define min image width/height. When an image is smaller then this values, we are going to show a warning and the user can choose to stop uploading or ignore warning and continue uploading
const MIN_IMAGE_WIDTH = 32;
const MIN_IMAGE_HEIGHT = 32;

const SUCCESS_MSG_DELAY = 1500;
const QUICK_ERROR_MSG_DELAY = 3000;

/**
 * define file upload states
 */
export const fileUploadStates = {
  LOADING:          'loading', //when component is initializing or loading in meta data from the api
  FILE_SELECT:      'file-select', //shows the 'file upload/select' view
  PROGRESS:         'progress', //shows the upload progress up until 100%
  SUCCEDED:         'succeded', //shows the 'success' message
  FAILED:           'failed', //will show the error and 'error handling' options
  WRONG_IMAGE_SIZE: 'wrong-image-size', //quick 'warning' message
  OVERVIEW:         'overview', //when the file asset ref is set, displays the thumbnail and meta info,
  VIDEO_INPUT:      'video-input', //set the video url
};

export const isExternalAsset = service => {
  const externalAssetServices = _.values(_.omit(assetServices, ['ASSET']));
  return _.includes(externalAssetServices, service);
};

/**
 * define messages used in the uploader
 */
export const UploaderMessages = {
  FILES_NOT_SUPPORTED_ERROR:             'File type(s) NOT supported: "#TYPE#"\n',
  EMPTY_FILES_ERROR:                     'Empty file(s) NOT supported: "#EMPTY_FILES#"\n',
  LARGE_FILES_ERROR:                     'Large file(s) NOT supported: "#LARGE_FILES#", max file size is #MAX_FILE_SIZE#',
  UPLOAD_SUCCESS:                        'Success',
  API_ERROR:                             'Api error.',
  IO_ERROR:                              'IO Error',
  GENERIC_UPLOAD_ERROR_HEADER:           'Oh no...',
  ERROR_HANDLING_BUTTON_REMOVE:          'Remove',
  ERROR_HANDLING_BUTTON_CONTINUE_ANYWAY: 'Ignore',
  ERROR_HANDLING_BUTTON_RETRY:           'Retry',
  ERROR_HANDLING_BUTTON_OVERWRITE:       'Overwrite',
  DRAG_AND_DROP_FILES_LABEL:             'Drag and drop file or',
  DRAG_AND_DROP_FILES_HERE:              'Drag and drop file here',
  ENTER_VIDEO_URL:                       'Enter a video url',
  UPLOAD_NEW:                            'Upload new',
  VIDEO_URL:                             'Video URL',
  VIDEO_HEADLINE:                        'Video',
  SELECT_FROM_LIBRARY:                   'Select from library',
  ACTION_REPLACE:                        'Replace',
  ACTION_REMOVE:                         'Remove',
  ACTION_PREVIEW:                        'Preview',
  ACTION_ADD_VIDEO_URL:                  'Add',
};

/**
 * Defines extensions for each file type. We are checking the selected files extension against this list, to get the file type and match it against the accepted file type
 * @type {*[]}
 */
export const DOCUMENT_TYPE_EXTENSIONS = {
  [documentTypes.IMAGE]: '*.jpg;*.jpeg;*.png;*.gif',
  [documentTypes.VIDEO]:
    '*.flv;*.avi;*.m1v;*.m2a;*.m2v;*.mjpg;*.moov;*.mov;*.movie;*.mpa;*.mpe;*.mpeg;*.mpg;*.qt;*.viv;*.vivo;*.wmv;*.rm;*.mp4',
  [documentTypes.AUDIO]:      '*.aif;*.aiff;*.m3u;*.mid;*.midi;*.mp2;*.mp3;*.mpga;*.ram;*.wav;*.m4a',
  [documentTypes.RIA]:        '*.swf;*.swc;*.fla;*.air;*.xml;*.lzx;*.jnlp;*.jar;*.js;*.xaml;*.dll',
  [documentTypes.PDF]:        '*.pdf;*.pdp',
  [documentTypes.POWERPOINT]: '*.lst;*.pot;*.pps;*.ppsx;*.ppt;*.pptx;*.pwz;*.thmx;*.otp;*.sdd;*.sdp;*.sti;*.sxi',
  [documentTypes.WORD]:
    '*.doc;*.docm;*.docx;*.dot;*.wiz;*.wzs;*.odc;*.odf;*.odm;*.odp;*.odt;*.otg;*.odh;*.ott;*.sdw;*.smd;*.stw;*.sxw',
  [documentTypes.EXCEL]:
    '*.dif;*.pxl;*.pxt;*.slk;*.xar;*.xl;*.xla;*.xlb;*.xlc;*.xld;*.xls;*.xlsx;*.xlt;*.xlthtml;*.xlw;*.ods;*.ots;*.sdc;*.sxc',
  [documentTypes.EXE]:  '*.exe;*.com;*.bat',
  [documentTypes.HTML]: '*.htm;*.html',
  [documentTypes.TXT]:  '*.txt',
  [documentTypes.CSV]:  '*.csv',
};

/**
 * define general structure for holding information on a file, holds defaults for a file item
 */
const FILE_ITEM_DEFAULTS = {
  assetRef:  '', //holds the asset ref that was set for this file item(if there is one)
  assetMeta: {}, //will hold all the information we can load in from showByRef for an 'assetRef'

  //file state props
  uploadState:          fileUploadStates.LOADING,
  uploadProgress:       0,
  uploadResponse:       {},
  overwrite:            false,
  errorHandlingOptions: { cancel: false, retry: false, overwrite: false, continue: false },

  //general file properties
  fileHandler: null,
  docType:     '',
  fileSize:    0,
  name:        '', //this will be the edited name without extension and with illegal characters stripped out
  fileName:    '', //this will be the actual file name with illegal characters stripped out
  fileDetails: '', //pretty size + other info we pre-compute so we don't re-create it each time

  //props for image files
  resolution:      { width: 0, height: 0 },
  resolutionLabel: '', //precomputed resolution to display
  isSmall:         false,
  thumbnailSize:   { width: 0, height: 0 },
  imageSrc:        null,

  lastUpdated: 0, //will be used to check for changes and stop updating the DOM on shouldComponentUpdate
  xhr:         null, //will point to the xhr when uploading a file
  handlers:    {
    onValueChange: () => {}, //this are going to be overriden by setHandler function
    onStateChange: () => {},
  }, //will keep track of the handlers registered here
};

/**
 * define upload error handling options
 */
export const ErrorHandlingOptions = {
  REMOVE:          'Remove',
  CONTINUE_ANYWAY: 'Ignore',
  RETRY:           'Retry',
  OVERWRITE:       'Overwrite',
};

/**
 * format a number of bytes in KB/MB/GB
 * @param size -  the bytes to format
 * @return {String} being formatted to bytes/KB/MB/GB
 */
export const prettyFileSize = size => {
  //compare size to byes/KB/MB/GB intervals and compute the sizes to be returned
  if (size < 1024) {
    return size + ' bytes';
  } else if (size < 1048576) {
    return Math.round((size * 10) / 1024) / 10 + 'KB';
  } else if (size < 1073741824) {
    return Math.round((size * 10) / 1048576) / 10 + 'MB';
  } else {
    return Math.round((size * 10) / 1073741824) / 10 + 'GB';
  }
};

/**
 * returns the files extension
 * @param fileName - the file name to check
 */
export const getFileExtension = fileName => {
  const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
  return extension.toLowerCase();
};

/**
 * returns the file name without extension
 * @param fileName
 * @returns {String}
 */
export const getFileNameWithoutExtension = fileName => {
  const pos = fileName.lastIndexOf('.');
  return pos > -1 ? fileName.substring(0, pos) : fileName;
};

/**
 * creates a file name that's accepted by the upload api. Will replace spaces with '_' character, and will remove unaccepted characters from the given file name
 * @param fName - the file name that needs to be escaped
 * @returns String - the escaped file name
 */
export const getEscapedFileName = fName => {
  return fName.replace(/\s/g, '_').replace(UNACCEPTED_FILE_NAME_CHARACTERS, '');
};

/**
 * returns the escaped file name without extension
 * replaces the "-" and "_" characters with a <space>, then it will replace multiple <space> characters with only one <space>
 * @param fName
 * @returns {string}
 */
export const getEscapedName = fName => {
  const ret = getEscapedFileName(getFileNameWithoutExtension(fName));
  return ret
    .replace(/-/g, ' ')
    .replace(/_/g, ' ')
    .replace(/\s{2,}/g, ' ');
};

/**
 * will get the document type by the files extension
 * @param file - the file that was browsed
 * @returns  {extension, docType}
 */
export const getDocumentTypeByExtension = file => {
  const extension = getFileExtension(file.name),
    docType = _.findKey(DOCUMENT_TYPE_EXTENSIONS, docExtensions => {
      return (
        docExtensions
          .replace(' ', '_')
          .split(';')
          .indexOf('*.' + extension) > -1
      );
    });

  return {
    extension,
    docType,
  };
};

/**
 * function will load in all image files and will try to set the size/dimension where possible
 * @param files - the file items that need to be checked against 'image' type and loaded into memory in order to display their thumbnails
 * @param filesLoadedCallback - function to be called when all image files have been read from the disk
 */
export const loadImageFiles = (files, filesLoadedCallback) => {
  let filesToLoad = files && files.length ? files.length : 0;
  const checkLoadStatus = function() {
    if (filesToLoad === 0) {
      //files have loaded
      filesLoadedCallback.call();
    }
  };

  //check if there are any files to load
  if (filesToLoad === 0) {
    checkLoadStatus();
    return;
  }

  files.map(file => {
    if (FileReader && file.fileHandler.type.match('image.*')) {
      //if the file is an image, load it's content and display it as a thumbnail, then start the upload
      const reader = new FileReader();
      reader.onload = function(evt) {
        const image = new Image(),
          fileData = { isSmall: false },
          imgSrc = evt.target.result;
        image.onload = function(evt) {
          fileData.resolution = { width: this.width, height: this.height };
          fileData.resolutionLabel = this.width && this.height ? this.width + 'x' + this.height : '';
          if (this.width < MIN_IMAGE_WIDTH || this.height < MIN_IMAGE_HEIGHT) {
            fileData.isSmall = true;
            fileData.uploadState = fileUploadStates.WRONG_IMAGE_SIZE;
            fileData.uploadResponse =
              'Image is too small. Image should be at least ' + MIN_IMAGE_WIDTH + 'x' + MIN_IMAGE_HEIGHT + '.';
            fileData.errorHandlingOptions = { cancel: true, continue: true };
          }
          fileData.imageSrc = imgSrc;
          filesToLoad--;
          Object.assign(file, fileData);
          checkLoadStatus();
        };
        image.onerror = function(evt) {
          filesToLoad--;
          checkLoadStatus();
        };
        image.src = imgSrc; //load in the image
      };
      reader.onerror = reader.onabort = function(evt) {
        filesToLoad--;
        checkLoadStatus();
      };
      reader.readAsDataURL(file.fileHandler); // Read in the image file as a data URL.
    } else {
      filesToLoad--;
      checkLoadStatus();
    }
    return file;
  });
};

/**
 * converts an 'external asset ref string' into a file meta data object
 * @param externalAssetRef - "weburl:image:auto://via.placeholder.com/800x200" OR "weburl:image:800x600://via.placeholder.com/800x200"
 */
export const externalAssetRefToMetaData = externalAssetRef => {
  const elements = externalAssetRef.split(':'),
    dimensions = elements[2].split('x'),
    externalImageArray = [],
    height = dimensions[1] || 0,
    width = dimensions[0] || 0,
    ConsoleKitConfig = ConfigService.values();
  let externalImagePath,
    fileName = '',
    mimeType = '';

  for (let i = 3; i < elements.length; i++) {
    externalImageArray.push(elements[i]);
  }

  externalImagePath = externalImageArray.join(':').replace(':large', '');
  if ( elements[0] === 'static') { // Handle static assets
    let urlPrefix = ConsoleKitConfig.assetUrls.staticResourceURL;
    // Eliminate the double slash problem if one ends with / and the other starts with /.
    if (urlPrefix.endsWith('/')) urlPrefix = urlPrefix.substring(0, urlPrefix.length -1);
    externalImagePath = urlPrefix + externalImagePath;
  } else if (externalAssetRef.indexOf('ugc:') >= 0) {
    externalImagePath = ConsoleKitConfig.assetUrls.accountUGCAssetURL + externalImagePath;
  } else if (externalAssetRef.indexOf('ugcx:') >= 0) {
    externalImagePath = ConsoleKitConfig.assetUrls.accountUGCxAssetURL + externalImagePath;
  }
  // we need a little trickery here if we have a video
  if (elements[1] === 'video') {
    // using this id we can get the proper thumbnail from you tube - using 0 rather than default gives us the larger version
    externalImagePath = 'http://img.youtube.com/vi/' + getYoutubeId(externalImagePath) + '/0.jpg';
    //externalSource = elements
  }
  if (elements[1] === 'file') {
    fileName = externalImagePath.substr(externalImagePath.lastIndexOf('/') + 1);
    externalImagePath = ConsoleKitConfig.assetUploader.defaultFileThumbnail;
  }

  if (externalAssetRef.indexOf('bundle:') >= 0) {
    externalImagePath = ConsoleKitConfig.assetUrls.consoleAssetURL + externalImagePath;
  }

  switch (elements[1]) {
    case 'image':
      mimeType = 'Image';
      break;
    case 'video':
      mimeType = 'Video';
      break;
    case 'file':
      mimeType = 'File';
      break;
    default:
      mimeType = _.capitalize(externalImagePath.substr(externalImagePath.lastIndexOf('.') + 1));
  }

  return {
    thumbnailAssetPath: externalImagePath,
    assetPath:          externalImagePath,
    classRef:           { label: 'External Asset' },
    mimeType:           { id: '', label: mimeType },
    createdBy:          { name: '' },
    modifiedBy:         { name: '' },
    externalSource:     '',
    externalAsset:      true,
    video:              elements[1] === 'video',
    file:               elements[1] === 'file',
    fileName:           fileName,
    width:              width,
    height:             height,
  };
};

/**
 * function is going to process the files and prepare them to show up in the queue. !!!Current version will support one file ONLY!!!
 * will try to read image content, file size, image resolution, file type
 * @param selectedFiles - selected/dropped file list
 * @param maxFileSize - max file size in bytes we accept for uploading
 * @param containerRef - container ref to upload the file against
 * @return {Object} - {error, files} - returns files that are accepted for upload and also returns possible errors found while processing the files
 */
const processFilesForUpload = (selectedFiles, maxFileSize, containerRef, acceptedDocumentTypes) => {
  const emptyFiles = [],
    unsupportedFileExtensions = [],
    largerFiles = [],
    filesToAdd = [];
  let i, currentFile, fileExt;

  //from the list of selected files we need to filter out the ones we don't support
  for (i = 0; i < 1 /*len*/; i++) {
    //currently supporting one file only!!!
    currentFile = selectedFiles[i];
    if (currentFile.size === 0) {
      //check if the user tries to upload an empty file. Our system isn't going to handle 0 sized files.
      emptyFiles.push(currentFile.name);
      continue;
    } else {
      //check to see if file extension is supported
      const fileInfo = getDocumentTypeByExtension(currentFile);
      //doing pre-validation on the file extension and size. If they are not supported or size is too big, constructing an error message to show. We do this to avoid unnecessary calls to the upload api and to save bandwidth
      if (acceptedDocumentTypes && acceptedDocumentTypes.indexOf(fileInfo.docType) > -1) {
        //check if the file type has size limitation
        if (maxFileSize === 0 || currentFile.size <= maxFileSize) {
          //check file size limit. maxFileSize == 0 means 'NO Limit'
          //file passed both type and size validation, it can be used for an upload, constructiong the file object
          const fItem = Object.assign({
            fileHandler:  currentFile,
            docType:      fileInfo.docType,
            fileSize:     currentFile.size,
            name:         getEscapedName(currentFile.name),
            fileName:     getEscapedFileName(currentFile.name),
            containerRef: containerRef,
          });
          fItem.fileDetails =
            'Asset | ' + getFileExtension(currentFile.name).toLowerCase() + ' | ' + prettyFileSize(fItem.fileSize);
          filesToAdd.push(fItem);
        } else {
          largerFiles.push(currentFile.name); //failed size validation
        }
      } else {
        fileExt = '.' + getFileExtension(currentFile.name); //failed supported extension validation
        if (unsupportedFileExtensions.indexOf(fileExt) === -1) {
          //we check to see if the unsupported extension is already added to the list that will show up in the error message. trying to avoid having the same extension twice or more in the error message
          unsupportedFileExtensions.push(fileExt);
        }
      }
    }
  }
  //Constructing the error message. Since we only support 1 file for now, this could be simpler, but leaving the code here for later updates when multi-upload will be supported
  let dropFilesError = '';
  if (unsupportedFileExtensions.length > 0) {
    dropFilesError +=
      UploaderMessages.FILES_NOT_SUPPORTED_ERROR.replace('#TYPE#', unsupportedFileExtensions.join(', ')) + '\n';
  }
  if (emptyFiles.length > 0) {
    dropFilesError += UploaderMessages.EMPTY_FILES_ERROR.replace('#EMPTY_FILES#', emptyFiles.join(', ')) + '\n';
  }
  if (largerFiles.length) {
    dropFilesError += UploaderMessages.LARGE_FILES_ERROR.replace('#LARGE_FILES#', largerFiles.join(', ')).replace(
      '#MAX_FILE_SIZE#',
      prettyFileSize(maxFileSize)
    );
  }
  //returns the list of accepted files for uploading and possibly the error message when unsupported files were selected/dropped to the component.
  return {
    error: dropFilesError,
    files: filesToAdd,
  };
};

/**
 *
 * @param fileItem - the file item to be uploaded
 * @param params - extra params that can be sent along the form data
 * @returns FormData containing the file and all parameters appended to the form, ready to be uploaded
 */
export const createFormData = (fileItem, params) => {
  const fileData = fileItem.fileData,
    fd = new FormData();
  if (params && typeof params === 'object') {
    for (const key in params) {
      fd.append(key, params[key]);
    }
  }
  if (fileData.containerRef !== null && fileData.containerRef.length > 0) {
    fd.append('containerRef', fileData.containerRef);
  }
  fd.append('overwrite', fileData.overwrite.toString());

  const fileName = getEscapedFileName(fileData.fileName);
  fd.append('fileName', fileName);
  if (fileData && fileData.fileHandler) {
    fd.append('sourcedata', fileData.fileHandler, fileName);
  }
  return fd;
};

/**
 * uploads one file item and calls 'uploadEndedCallbackFn' when the upload ended with success or error
 * @param file - the file item to be uploaded
 * @param uploadEndedCallbackFn - function to be called on success or error
 */
const uploadFile = (uploadUrl, fItem, uploadEndedCallbackFn) => {
  const xhr = new XMLHttpRequest(),
    fd = createFormData(fItem);

  const ConsoleKitConfig = ConfigService.values();

  xhr.open(ConsoleKitConfig.assetUploader.postMethod, uploadUrl, true);

  //listening for 'progress' events
  xhr.upload.addEventListener(
    'progress',
    evt => {
      const loaded = evt.lengthComputable ? evt.loaded : 0,
        total = evt.lengthComputable ? evt.total : 0,
        cFileData = fItem.fileData,
        cFileProgress = cFileData ? cFileData.uploadProgress : 0;
      let currentProgress = total > 0 ? Math.floor((loaded / total) * 100) : 0;

      currentProgress -= currentProgress % 5; //only increment at each 5 percent

      if (cFileProgress !== currentProgress) {
        fItem.updateFile({
          uploadState:    fileUploadStates.PROGRESS,
          uploadProgress: currentProgress,
        });
      }
    },
    false
  );

  //listening for 'load' events
  xhr.addEventListener(
    'load',
    evt => {
      if (evt.currentTarget.readyState === 4 && evt.currentTarget.status === 200) {
        const response = JSON.parse(evt.target.responseText);
        if (response && response.status) {
          switch (response.status.toLowerCase()) {
            case 'ok':
              //this means our file uploaded successfully
              fItem.updateFile({
                uploadState:    fileUploadStates.SUCCEDED,
                uploadResponse: response,
              });

              break;
            case 'error':
              //if the status is error, then the 'errorCode' has to be defined on the json response
              fItem.updateFile({
                uploadState:          fileUploadStates.FAILED,
                uploadResponse:       response,
                errorHandlingOptions: {
                  cancel:    true,
                  retry:     true,
                  continue:  false,
                  overwrite: response.errorCode.toUpperCase() === 'FILE_ALREADY_EXISTING',
                },
              });
              break;
          }
        } else {
          fItem.updateFile({
            uploadState:          fileUploadStates.FAILED,
            uploadResponse:       UploaderMessages.API_ERROR,
            errorHandlingOptions: { cancel: true, retry: true },
          });
        }
      } else {
        fItem.updateFile({
          uploadState:          fileUploadStates.FAILED,
          uploadResponse:       UploaderMessages.IO_ERROR,
          errorHandlingOptions: { cancel: true, retry: true },
        });
      }
      uploadEndedCallbackFn.call();
    },
    false
  );

  //listening for upload errors
  xhr.addEventListener(
    'error',
    evt => {
      fItem.updateFile({
        uploadState:          fileUploadStates.FAILED,
        uploadResponse:       evt.currentTarget.responseText,
        errorHandlingOptions: { cancel: true, retry: true },
      });
      uploadEndedCallbackFn.call();
    },
    false
  );

  //listening for upload errors
  xhr.addEventListener(
    'abort',
    evt => {
      //this is not an error, user has aborted the upload
      uploadEndedCallbackFn.call();
    },
    false
  );

  //start the upload process
  xhr.send(fd);

  fItem.updateFile({
    uploadState:          fileUploadStates.PROGRESS,
    uploadProgress:       0,
    uploadResponse:       {},
    overwrite:            false,
    errorHandlingOptions: { cancel: false, retry: false, overwrite: false, continue: false },
    xhr:                  xhr,
  });
};

/**
 * encapsulates all functionality to create/update/upload/handle errors on a file object
 * @param defaults - default values to be used when creating a new file item
 * @constructor
 */
export const FileItem = function(defaults) {
  const me = this;

  me.fileData = Object.assign({}, FILE_ITEM_DEFAULTS, defaults); //copy defaults and create the file object for the 'file item'

  //if there is any ongoing delayed functionality, stop or cancel it
  me.cancelAsyncFunctionality = () => {
    if (me.delayedErrorMessageTimeout){
      clearTimeout(me.delayedErrorMessageTimeout);
      me.delayedErrorMessageTimeout = undefined;
    }
    if (me.showOverviewTimeout){
      clearTimeout(me.showOverviewTimeout);
      me.showOverviewTimeout = undefined;
    }
    if (me.metadataXhr){
      me.metadataXhr.abort();
      me.metadataXhr = undefined;
    }
  }

  /**
   * Updates properties on the file object.
   * If handlers are defined, it will call the handlers.onStateChange function with the updated 'file objects' data
   * @param updateProps - props to be updated on the 'file object'
   * @returns the updated file object
   */
  me.updateFile = updateProps => {
    const updatedFileData = Object.assign(this.fileData, updateProps, {
      lastUpdated: new Date().getTime(),
    });
    updatedFileData.handlers.onStateChange.call(this, updatedFileData);

    if (updateProps.hasOwnProperty('uploadState') && updateProps.uploadState === fileUploadStates.SUCCEDED) {
      me.setAssetRefData(updateProps.uploadResponse.asset.assetRef, false, true, SUCCESS_MSG_DELAY);
    }

    return updatedFileData;
  };

  /**
   * will reset the file obj to defaults. Will leave the handlers untouched.
   * @param updateProps- if passed, it will update the props on the item
   * @returns {Object} - the updated file object
   */
  me.resetFile = updateProps => {
    const cleanFileItem = Object.assign({}, FILE_ITEM_DEFAULTS, updateProps);
    delete cleanFileItem.handlers; //so that we don't reset the registered handlers
    return me.updateFile(cleanFileItem);
  };

  /**
   * processes the given files, either shows an error OR starts the upload process of the newly passed file
   * @param uploadUrl - url to upload to
   * @param files - list of files to be uploaded to the url
   * @param maxFileSize - max file size to be accepted for upload
   * @param containerRef - container ref to upload the file against
   */
  me.uploadNewFile = (uploadUrl, files, maxFileSize, containerRef, acceptedDocumentTypes) => {
    const processedFiles = processFilesForUpload(files, maxFileSize, containerRef, acceptedDocumentTypes);
    if (processedFiles.error) {
      me.updateFile({
        uploadState:          fileUploadStates.FAILED,
        uploadResponse:       { errorMessage: processedFiles.error },
        errorHandlingOptions: { cancel: false, retry: false, overwrite: false, continue: false },
      });

      me.delayedErrorMessageTimeout = setTimeout(() => {
        me.updateFile({
          uploadState: fileUploadStates.FILE_SELECT,
        });
      }, QUICK_ERROR_MSG_DELAY);
    }
    if (processedFiles.files.length > 0) {
      loadImageFiles(processedFiles.files, () => {
        const processedFile = processedFiles.files[0];
        me.updateFile(processedFile);
        if (
          processedFile.uploadState !== fileUploadStates.FAILED &&
          processedFile.uploadState !== fileUploadStates.WRONG_IMAGE_SIZE
        ) {
          me.startFileUpload(uploadUrl);
        }
      });
    }
  };

  /**
   * sets up all the variables for the upload and starts the upload process of a file
   * @param uploadUrl - url to upload the files to
   */
  me.startFileUpload = uploadUrl => {
    const ConsoleKitConfig = ConfigService.values();
    //use the uploadUrl passed in to the component. If not given, use the one defined in the ConsoleKitConfig.
    //make sure a fileHandler exists for the file
    if (me.fileData && me.fileData.fileHandler) {
      uploadFile(!uploadUrl ? ConsoleKitConfig.assetUploader.uploadUrl : uploadUrl, me, () => {
        /* called when upload ends. */
      });
    }
  };

  /**
   * updates the handlers on a 'file object'
   */
  (me.setHandlers = handlers => {
    return me.updateFile({ handlers: handlers });
  }),
    /**
     * handles an upload error and restarts the upload process of the file if needed
     * @param errorHandlingOption
     * @param uploadUrl
     */
    (me.handleUploadError = (errorHandlingOption, uploadUrl) => {
      switch (errorHandlingOption) {
        case ErrorHandlingOptions.OVERWRITE:
          me.updateFile({ uploadResponse: '', errorHandlingOptions: {}, overwrite: true });
          me.startFileUpload(uploadUrl);
          break;
        case ErrorHandlingOptions.RETRY:
        case ErrorHandlingOptions.CONTINUE_ANYWAY:
          me.updateFile({ uploadResponse: '', errorHandlingOptions: {}, overwrite: false });
          me.startFileUpload(uploadUrl);
          break;
        case ErrorHandlingOptions.REMOVE:
          me.resetFile({ uploadState: fileUploadStates.FILE_SELECT });
          me.setAssetRefData('', false, true, 0);
          break;
      }
    });

  /**
   * sets the asset ref on the upload item, without doing any other processing(like setAssetRefData, that will try to load in meta data from an api)
   */
  me.updateVideoUrl = videoUrl => {
    //building out the video url ref
    const assetRef = videoUrl ?
      assetRefService.serialize({
        service:   assetServices.WEB_URL,
        assetType: uploadAssetTypes.VIDEO_URL,
        dim:       'auto',
        location:  videoUrl,
      }) :
      '';
    //sets the assetRef on the file item
    me.updateFile({ assetRef: assetRef });
    me.fileData.handlers.onValueChange.call(me, me.fileData.assetRef || '');
  };

  /**
   * when clicking the 'add' video url button, it will get back to the file select OR file overview screen, depending on the url location existence.
   */
  me.acceptVideoUrl = () => {
    const assetRefObj = assetRefService.deserialize(me.fileData.assetRef);
    if (assetRefObj.location) {
      me.updateFile({ uploadState: fileUploadStates.OVERVIEW });
    } else {
      me.updateFile({
        uploadState: fileUploadStates.FILE_SELECT,
        assetRef:    '', //the location of the video url was left empty, remove the asset ref
      });
    }
  };

  /**
   * sets the asset ref and meta data on the file item. It will then show the 'overview' state if asset ref is given
   * @param assetRef - the asset ref to be set. For external refs, we compute 'meta' data from the assetRef itself. For internal assetRefs we load in the 'meta' information from our apis.
   * @param assetMeta - optional, when given, we use the 'meta' data passed in instead of loading from the api(*only used for internally stored assets). When selecting an asset from the library, we already have the metadata loaded, so it's easier/faster to pass it in.
   * @param overviewDelay - optional, will wait the given ms before setting/showing the 'overview' state(useful for maintaining the 'success' state after an upload)
   */
  me.setAssetRefData = (assetRef, assetMeta, dispatchChange, overviewDelay = 0) => {
    me.cancelAsyncFunctionality(); //in case we had any functionality like loading in asset metadata or waiting for overview/error to diplay: STOP the processes

    //standard error to be set on the file item in case of 'meta' related errors
    const showStandardRefError = errMsg => {
      me.updateFile({
        uploadState:          fileUploadStates.FAILED,
        uploadResponse:       errMsg,
        errorHandlingOptions: { cancel: true, retry: true },
      });
    };

    //adds timer to set state to 'overview' (hides the success msg and displays meta info)
    const setFileMetaData = metaData => {
      me.updateFile({
        docType:   getDocumentTypeByExtension({ name: metaData.name ? metaData.name : '' }).docType,
        assetMeta: Object.assign({}, metaData, {
          allowImport: metaData.externalAsset && !(metaData.video || metaData.file),
        }),
      });
      me.showOverviewTimeout = setTimeout(() => {
        me.updateFile({ uploadState: fileUploadStates.OVERVIEW });
      }, overviewDelay);
    };

    //loads meta data for an internal asset ref
    const loadFileMetaData = () => {
      if (overviewDelay === 0) {
        me.updateFile({ uploadState: fileUploadStates.LOADING });
      }
      if (typeof FormData === 'undefined') return; //mocha test fails because FormData is missing. Not sure how to fix the tests, for now, this will stop test failures.
      const fd = new FormData();      
      const ConsoleKitConfig = ConfigService.values();
      //internal asset, we have to load in meta data from the api      
      fd.append('assetRef', assetRef);
      me.metadataXhr = new XMLHttpRequest()
      me.metadataXhr.open(ConsoleKitConfig.assetUploader.postMethod, ConsoleKitConfig.assetUrls.showByRef, true);
      me.metadataXhr.addEventListener(
        'load',
        evt => {
          if (evt.currentTarget.readyState === 4 && evt.currentTarget.status === 200) {
            const response = JSON.parse(evt.target.responseText);
            if (response && response.status && response.status === 'ok') {
              setFileMetaData(response.content[0]);
            } else {
              showStandardRefError(response.errorMessage);
            }
          } else {
            showStandardRefError(UploaderMessages.IO_ERROR);
          }
        },
        false
      );
      me.metadataXhr.addEventListener(
        'error',
        evt => {
          showStandardRefError(evt.currentTarget.responseText);
        },
        false
      );
      me.metadataXhr.send(fd);
    };

    //sets the assetRef on the file item
    me.updateFile({ assetRef: assetRef });
    if (dispatchChange && typeof me.fileData.handlers.onValueChange === 'function') {
      me.fileData.handlers.onValueChange.call(me, assetRef);
    }

    if (assetRef && assetRef.length > 0) {
      //check if it's an external asset ref
      const assetRefObj = assetRefService.deserialize(me.fileData.assetRef);
      if (assetRefObj.service === assetServices.WEB_URL && assetRefObj.assetType === uploadAssetTypes.VIDEO_URL) {
        me.updateFile({ uploadState: fileUploadStates.OVERVIEW });
      } else if (isExternalAsset(assetRefObj.service)) {
        setFileMetaData(externalAssetRefToMetaData(assetRef));
      } else if (assetMeta) {
        //internal asset where meta data is given(ex. when the asset ref is choosen using the 'select from library' feature, we already have the asset meta data, no need to load it in)
        setFileMetaData(assetMeta);
      } else {
        loadFileMetaData();
      } //internal asset ref is given, need to load in meta data from the api
    } else {
      //asset ref is empty, move the uploader in the 'upload/select file' state
      me.updateFile({ uploadState: fileUploadStates.FILE_SELECT });
    }
  };
};

/**
 * store for holding several file items data during mount/unmount and state changes of the 'Light Uploader' components
 */
export const FileUploaderStore = {
  files: {}, // will hold the file objects for different uploaders

  /**
   * creates the FileItem object to be used with a given 'fileId'
   * @param fileId - unique file uploader id
   * @returns the FileItem object that was created
   */
  registerNewUploader: fileId => {
    if (FileUploaderStore.files[fileId]) {
      return false;
    } //fileId already exists
    return (FileUploaderStore.files[fileId] = new FileItem({ lastUpdated: new Date().getTime() })); //creates the new FileItem
  },

  /**
   * returns the FileItem that was registered with the given 'fileId'
   * @param fileId - unique file uploader id
   * @returns {*} the FileItem
   */
  getFile: fileId => {
    if (FileUploaderStore.files[fileId]) return FileUploaderStore.files[fileId];
    return false;
  },

  /**
   * removes a FileItem from the store
   * @param fileId
   */
  removeFile: fileId => {
    if (FileUploaderStore.files[fileId]) delete FileUploaderStore.files[fileId];
  },

  /**
   * Removes multiple FileItem-s from the store.
   * @param fileIdPrefix - all file id's having this prefix will be removed
   */
  removeFiles: fileIdPrefix => {
    const uploaderFiles = FileUploaderStore.files;
    for (const fileId in uploaderFiles) {
      // skip loop if the property is from prototype
      if (!uploaderFiles.hasOwnProperty(fileId)) continue;

      if (fileId.indexOf(fileIdPrefix) === 0) {
        FileUploaderStore.removeFile(fileId);
      }
    }
  },
};

export function isAssetPortrait(width, height) {
  return width < height;
}

export const FILE_TYPES = {
  ALL:   '',
  IMAGE: 'type.asset.image',
  AUDIO: 'type.asset.audio',
  VIDEO: 'type.asset.video',
  RIA:   'type.asset.ria',
  FILE:  'type.asset.file',
};

/**
 * returns the file type based on the document types array
 * @param docTypes - array of documentTypes
 * @returns {string} - fileType for the given document types
 */
export function getFileTypeFromDocumentType(docTypes) {
  let fileType = FILE_TYPES.ALL;
  const PURE_DOC_TYPES = [documentTypes.IMAGE, documentTypes.AUDIO, documentTypes.VIDEO, documentTypes.RIA];

  if (docTypes && docTypes.length > 0) {
    if (docTypes.length === 1) {
      //if there is only one doc type, it's easy to match to file type
      if (PURE_DOC_TYPES.indexOf(docTypes[0]) > -1) {
        fileType = 'type.asset.' + docTypes[0];
      } else if (_.values(documentTypes).indexOf(docTypes[0]) > -1) {
        fileType = FILE_TYPES.FILE;
      }
    } else {
      const len = docTypes.length;
      let i,
        hasPureDoc = false;
      //check if any of the doc types is pure
      for (i = 0; i < len; i++) {
        if (PURE_DOC_TYPES.indexOf(docTypes[i]) > -1) {
          hasPureDoc = true;
          break;
        }
      }
      if (!hasPureDoc) {
        fileType = FILE_TYPES.FILE;
      }
    }
  }
  return fileType;
}
