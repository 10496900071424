import React from 'react';
import { createComponent, PropTypes, Utils } from 'wayin-react';
import classnames from 'classnames';
import { toUIProps, extractElement } from 'helpers';
import { positions, sizes, colors, directions } from 'enums';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'react-recompose';

import { Menu as UIMenu } from 'semantic-ui-react';
import MenuItem, { PrivateMenuItem } from 'components/core/menu/menu-item';
import PopupMenuItem from 'components/abstractions/popup-menu/popup-menu-item';
import Submenu, { PrivateSubmenu } from 'components/core/menu/menu-submenu';
import Icon from 'components/core/icon';
import Label from 'components/core/label';

const UNSUPPORTED_PROPS = [
  'as',
  'attached',
  'className',
  'fixed',
  'fluid',
  'stackable',
  'tabular',
  'widths',
  'pointing',
  'borderless',
  'secondary',
  'icon',
  'inverted',
  'defaultActiveIndex',
  'activeIndex',
];

const ADDED_PROPS = [
  'isCompact',
  'isDisabled',
  'isFluid',
  'isInverted',
  'isPaginated',
  'isVertical',
  'isTextOnly',
  'isUnderlined', // uses 'secondary' and 'pointing' together to make an underlined tab group
  'borders', // combines 'borderless' and 'secondary' more intuitively.
  'fitted',
  'iconTextDisplay', // renames icon=true or icon='labeled' more intuitively.
  'label',
  'menuType',
  'activeValue',
  'style',
];

const MODIFIED_PROPS = [
  'onItemClick',
  'items',
  'color', // allows us to pass it as a classname vs. a prop to avoid semantic-ui-react warnings
  'size',
  'float',
];

const propMap = {
  isCompact:   'compact',
  isFluid:     'fluid',
  isInverted:  'inverted',
  isPaginated: 'pagination',
  isVertical:  'vertical',
  isTextOnly:  'text',
};

const allowedMenuTypes = ['asset-radio-menu', 'button-menu', 'popup-menu', 'icon-menu', 'text-menu'];

const Menu = createComponent({
  displayName: 'Menu',
  propTypes:   {
    children: PropTypes.node,
    items:    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          tooltipPosition:    PropTypes.string,
          tooltipIsInline:    PropTypes.bool,
          tooltipIsCompact:   PropTypes.bool,
          tooltipIsMultiline: PropTypes.bool,
          tooltip:            PropTypes.string,
          text:               PropTypes.string,
          icon:               PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
          value:              PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          isDivided:          PropTypes.bool,
        }),
      ])
    ), // mutually exclusive with children
    activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size:        PropTypes.oneOf(sizes.ALL),
    float:       PropTypes.oneOf(positions.LR),
    color:       PropTypes.oneOf(colors.CORE),
    label:       PropTypes.oneOfType([
      PropTypes.element, //Label
      PropTypes.string,
    ]),

    // allows passing classname for asset radio menu for very specific styling
    menuType: PropTypes.oneOf(allowedMenuTypes),

    // all = default; noDividers = no line between buttons, has outer border; none = no lines, no outer border
    borders: PropTypes.oneOf(['all', 'noDividers', 'none']),

    // inline = text to the left or right of icon, block = text above or below icon, and no text means remove
    // padding to optimize for icon only menus.  In the case of block & inline, the position is set by the order
    // of the children.  If the text preceeds the icon, it will be above or to the left, etc.
    iconTextDisplay: PropTypes.oneOf(['inline', 'block', 'noText']), // inline = default

    fitted:       PropTypes.oneOf(directions.BASIC),
    isCompact:    PropTypes.bool,
    isDisabled:   PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isFluid:      PropTypes.bool,
    isPaginated:  PropTypes.bool,
    isVertical:   PropTypes.bool,
    isTextOnly:   PropTypes.bool, // makes menu borderless with current item highlighting indicated by bolding the text
    isUnderlined: PropTypes.bool, // pointing && secondary = underlined tab

    onItemClick: PropTypes.func,
  },
  defaultProps: {
    borders:         'none',
    activeValue:     null,
    children:        null,
    size:            null,
    iconTextDisplay: 'inline',
    float:           null,
    color:           null,
    label:           null,
    menuType:        null,
    fitted:          null,
    isCompact:       false,
    isDisabled:      false,
    isFluid:         false,
    isPaginated:     false,
    isVertical:      false,
    isTextOnly:      false,
    isUnderlined:    false,
    items:           undefined, // shows warning with null value
    onItemClick:     null,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });
    let items;

    UIProps.borderless = props.borders === 'noDividers';
    UIProps.secondary = props.borders === 'none' || props.isUnderlined;
    UIProps.pointing = props.isUnderlined;

    // icon {bool|enum}: bool = true removes right icon margin; or enum = 'labeled' puts text below icon
    if (props.iconTextDisplay === 'block') UIProps.icon = 'labeled';
    else if (props.iconTextDisplay === 'noText') UIProps.icon = true;
    else UIProps.icon = false; // if (iconTextDisplay==='inline') and all other cases

    // float {bool|enum}: Need to send it true for left, 'right' for right.  :(
    if (props.float === positions.LEFT) UIProps.floated = true;
    else if (props.float === positions.RIGHT) UIProps.floated = positions.RIGHT;
    else UIProps.floated = false;

    logger.warn(
      'Menu: isFluid and float properties are mutually exclusive. Please choose one or the other.',
      props.isFluid && !!props.float
    );
    logger.warn(
      'Children cannot be defined with "items", please choose one or the other, in Menu',
      !_.isEmpty(props.items) && !_.isEmpty(props.children)
    );

    UIProps.className = classnames(props.size, props.color, {
      [`ck-${props.menuType}`]: !!props.menuType && _.includes(allowedMenuTypes, props.menuType),
      fitted:                   !!props.fitted,
      disabled:                 !!props.isDisabled,
    });

    if (!_.isEmpty(props.items)) {
      items = extractItemsFromProps(props);
    } else if (!_.isEmpty(props.children)) {
      items = extractItemsFromChildren(props);
    }

    const label = extractElement(Label, props.label, 'text');

    const wrapperClassName = classnames('ck-menu-wrapper', {
      'float-left':             props.float === positions.LEFT,
      'float-right':            props.float === positions.RIGHT,
      [`ck-${props.menuType}`]: !!props.menuType && _.includes(allowedMenuTypes, props.menuType),
    });
    return (
      <div className={wrapperClassName} style={props.style}>
        {label ? label.element : null}
        <UIMenu {...UIProps}>{items}</UIMenu>
      </div>
    );
  },
});

function isSubmenu(element) {
  return Utils.isType([Submenu, PrivateSubmenu], element);
}

function isMenuItem(element) {
  return Utils.isType([MenuItem, PrivateMenuItem, PopupMenuItem], element);
}

function extractItemsFromChildren(props) {
  return _.map(React.Children.toArray(props.children), (child, idx) => {
    if (isSubmenu(child)) {
      const childProps = Object.assign({}, child.props, {
        activeValue: props.activeValue,
        onItemClick: props.onItemClick,
        id:          props.id,
        key:         idx,
      });
      return <PrivateSubmenu {...childProps} />;
    } else if (isMenuItem(child)) {
      const value = child.props.hasOwnProperty('value') ? child.props.value : idx;
      const childProps = Object.assign({}, child.props, {
        isActive: props.activeValue == value,
        onClick:  child.props.onClick || props.onItemClick,
        value,
        id:       props.id,
        key:      idx,
      });
      return React.cloneElement(child, childProps);
    } else {
      // its a Menu!
      return (
        <PrivateSubmenu activeValue={props.activeValue} onItemClick={props.onItemClick} id={props.id} key={idx}>
          {child}
        </PrivateSubmenu>
      );
    }
  });
}

function extractItemsFromProps(props) {
  return _.map(props.items, (item, idx) => {
    let icon = extractElement(Icon, item.icon, { name: item.icon });
    const value = item.hasOwnProperty('value') ? item.value : idx;
    const text = typeof item === 'string' ? item : item.hasOwnProperty('text') ? item.text : null;
    const isDivided = item.hasOwnProperty('isDivided') ? item.isDivided : false;
    logger.warn(
      'IconMenu is not intended for use with text.  Please use TextMenu instead.',
      props.menuType === 'icon-menu' && text !== null
    );

    if (!!icon && !icon.element.props.color) {
      icon = Object.assign({}, { element: React.cloneElement(icon.element, { color: colors.BASE }) });
    }
    return (
      <PrivateMenuItem
        onClick={item.onClick || props.onItemClick}
        isActive={value == props.activeValue}
        fitted={props.fitted} // override default menu item fitted prop
        value={value}
        key={idx}
        isDisabled={item.isDisabled}
        isDivided={isDivided}
        id={props.id}
        tooltip={item.tooltip}
        tooltipPosition={item.tooltipPosition}
        tooltipIsInline={item.tooltipIsInline}
        tooltipIsCompact={item.tooltipIsCompact}
        tooltipIsMultiline={item.tooltipIsMultiline}
      >
        {!!icon ? icon.element : null}
        {text}
      </PrivateMenuItem>
    );
  });
}

export const PrivateMenu = whitelistStyles()(Menu);
export default compose(
  removeProps([
    'color',
    'menuType',
    'borders',
    'iconTextDisplay',
    'fitted',
    'isCompact',
    'isDisabled',
    'isPaginated',
    'isTextOnly',
    'isUnderlined',
    'items',
    'size',
    'secondary',
  ])
)(PrivateMenu);
