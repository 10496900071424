import { PrivateAssetUploader } from './asset-uploader';
import { compose, setDisplayName, withProps } from 'react-recompose';
import { removeProps } from 'components/core/hoc';

const ImageAssetUploader = compose(
  setDisplayName('ImageAssetUploader'),
  withProps({
    showUploadButton:   true,
    showSelectButton:   true,
    showVideoUrlButton: false,
  })
)(PrivateAssetUploader);

export default ImageAssetUploader;