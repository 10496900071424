import React from "react";

const withRefs = refsMap => BaseComponent => {
  
  const factory = (props) => <BaseComponent {...props}/>;

  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { rerender: false };
    }
    render() {
      const refProps = _.reduce(
        refsMap,
        (acc, setRefName, refName) => {
          acc.refs[refName] = this[refName];
          /* React calls this once on mount, with the element, and once on unmount, with null */
          acc[setRefName] = element => {
            acc.refs[refName] = this[refName] = element;
          };
          return acc;
        },
        { refs: {} }
      );

      return factory({ ...this.props, ...refProps });
    }
  };
};

export default withRefs;
